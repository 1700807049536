import WcIcon from "@mui/icons-material/Wc";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import chatIcon from "../../assets/icons/chatIcon.svg";
import vedazTalkStyles from "./VedazTalk.styles";

interface CustomProps {
  setOpenPreference: Function;
}

const WelcomeScreen = (props: CustomProps) => {
  const classes = vedazTalkStyles;
  const { t } = useTranslation();

  return (
    <Box sx={classes.welcomeBoxWrapper}>
      <Box sx={classes.welcomeBox}>
        <Box
          component="img"
          src={chatIcon}
          alt="Welcome"
          sx={classes.chatImage}
        />
        <Typography sx={classes.welcomeHeader}>{t("welcomeHeader")}</Typography>
        <Typography sx={classes.selectUserText}>{t("welcomeText")}</Typography>

        <Button
          onClick={() => props.setOpenPreference(true)}
          startIcon={<WcIcon />}
          sx={classes.preferenceBtn}
        >
          <Typography sx={classes.preferenceBtnText}>
            {t("preferenceBtnText")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default WelcomeScreen;
