import urls from "../../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../../utils/service";

export const compatibilityAllUser = async (email: string) => {
  try {
    const callParams = await getCallParams("POST", { email });
    const response = await makeCall(urls.compatibilityAllUser, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
