import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomIcon } from "../../../../global/components";
import { handleErrorMessage } from "../../../../helpers/methods";
import { boldFont } from "../../../../utils/styles";
import analysisStyles from "../../Analysis.styles";
import { getSingleDashaComponentData } from "../../AnalysisService";

interface CustomProps {
  profileId: string;
  subTabValue: string;
  setIsLoading: Function;
}

const SingleDashaComponent = (props: CustomProps) => {
  const classes = analysisStyles;
  const [dashaData, setDashaData] = useState([]);

  const planetColorMapping: any = {
    Ketu: "white",
    Venus: "#22cb22",
    Sun: "#22cb22",
    Moon: "#22cb22",
    Mars: "red",
    Rahu: "white",
    Jupiter: "#22cb22",
    Saturn: "red",
    Mercury: "#22cb22",
  };

  useEffect(() => {
    getSingleDashaComponentDetails();
  }, [props.subTabValue]);

  const getSingleDashaComponentDetails = async () => {
    try {
      props.setIsLoading(true);
      const response = await getSingleDashaComponentData(
        props?.profileId!,
        props.subTabValue
      );
      setDashaData(response.dasha);
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const getSingleDashaComponent = () => {
    return (
      <Grid container py={2} sx={{ rowGap: "15px" }}>
        {dashaData?.map((data: any) => {
          return (
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ ...classes.mahadashaContent, cursor: "auto" }}
            >
              <Stack direction={"column"} pl={2}>
                <Typography
                  sx={{
                    ...classes.planetNameTypo,
                    ...(dashaData.length === 4 && boldFont),
                  }}
                  pb={1.5}
                >
                  {data.planetName
                    .split(/[\s-]+/)
                    .map((part: string, index: number, arr: any) => (
                      <React.Fragment key={index}>
                        <span
                          style={{
                            color: planetColorMapping[part.trim()]
                              ? planetColorMapping[part.trim()]
                              : "white",
                          }}
                        >
                          {part}
                        </span>
                        {index < arr.length - 1 && (
                          <span style={{ color: "white" }}> - </span>
                        )}
                      </React.Fragment>
                    ))}
                </Typography>

                <Stack
                  direction={"row"}
                  spacing={1}
                  pt={1.5}
                  sx={{
                    width: "fit-content",
                    borderTop: "2px solid #ffffff73",
                  }}
                >
                  <Typography sx={classes.dateTypo}>
                    {data.startDate}
                  </Typography>
                  <CustomIcon
                    icon={<HorizontalRuleIcon htmlColor="#9F3AFF" />}
                  />
                  <Typography sx={classes.dateTypo}>{data.endDate}</Typography>
                </Stack>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return getSingleDashaComponent();
};

export default SingleDashaComponent;
