import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import strings from "./global/constants/StringConstants";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: "AIzaSyAZQYWEoPItEDOPzYOVtc8lqVX2NIjs4Sg",
  authDomain: "astrology-4485f.firebaseapp.com",
  projectId: "astrology-4485f",
  storageBucket: "astrology-4485f.firebasestorage.app",
  messagingSenderId: "1082748052998",
  appId: "1:1082748052998:web:4ea1d9ee2d2b4d9a0d4587",
  measurementId: "G-JMZ9DXP5FZ",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  try {
    if (
      typeof window.Notification !== "undefined" &&
      "Notification" in window
    ) {
      const permission = await window.Notification.requestPermission();
      if (permission === "granted") {
        const currentToken = await getToken(messaging, {
          vapidKey: strings.VAPID_KEY,
        });
        if (currentToken) {
          return currentToken;
        } else {
          throw { errorMessage: "No registration token available." };
        }
      } else {
        throw { errorMessage: "Permission denied for notifications." };
      }
    } else {
      console.log("Error from browser");
    }
  } catch (error: any) {
    throw error;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
