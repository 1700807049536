import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Location } from "history";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import analysisIcon from "../../assets/icons/analysisIcon.svg";
import circularChartIcon from "../../assets/icons/circularChartIcon.svg";
import footballIcon from "../../assets/icons/footballIcon.svg";
import mahadashaIcon from "../../assets/icons/mahadashaIcon.svg";
import missingMineralsIcon from "../../assets/icons/missingMineralsIcon.svg";
import nakshatraIcon from "../../assets/icons/nakshatraIcon.svg";
import planetsIcon from "../../assets/icons/planetsIcon.svg";
import homeImage from "../../assets/images/homeImage.png";
import strings from "../../global/constants/StringConstants";
import urls from "../../global/constants/UrlConstants";
import { selectAuthenticated } from "../../redux/authSlice";
import history from "../../utils/history";
import { useAppSelector } from "../../utils/hooks";
import { LightTooltip } from "../../utils/styles";
import AppFooter from "../Shared/AppFooter/AppFooter";
import AppHeader from "../Shared/AppHeader/AppHeader";
import homeStyles from "./Home.styles";

interface CustomProps {
  location?: Location;
}

const Home = (props: CustomProps) => {
  const classes = homeStyles;
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const features = [
    {
      tooltip: "analysisTooltip",
      icon: analysisIcon,
      header: "analysisHeader",
    },
    {
      tooltip: "mineralsTooltip",
      icon: missingMineralsIcon,
      header: "mineralsHeader",
    },
    {
      tooltip: "mahadashaTooltip",
      icon: mahadashaIcon,
      header: "mahadashaHeader",
    },
    {
      tooltip: "planetDetailsTooltip",
      icon: planetsIcon,
      header: "planetHeader",
    },
    {
      tooltip: "nakshashtraTooltip",
      icon: nakshatraIcon,
      header: "nakshatraHeader",
    },
    {
      tooltip: "circularChartTooltip",
      icon: circularChartIcon,
      header: "circularChartHeader",
    },
    {
      tooltip: "footballPositionTooltip",
      icon: footballIcon,
      header: "footballHeader",
    },
  ];

  const getContent = () => {
    return (
      <Box sx={classes.content}>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Stack
                direction={"column"}
                justifyContent={"space-between"}
                sx={classes.contentWrapper}
              >
                <Stack direction={"column"}>
                  <Container maxWidth="xl">
                    <Grid container alignItems={"center"}>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        px={{ md: 2, xs: 0 }}
                        sx={classes.center}
                      >
                        <img
                          src={homeImage}
                          width={"100%"}
                          style={{ maxWidth: "550px" }}
                          alt="Module Note Found..."
                        />
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        px={{ md: 2, xs: 0 }}
                        sx={classes.center}
                      >
                        <Stack direction={"column"} pt={{ md: 0, xs: 3 }}>
                          <Typography sx={classes.italicTypo}>
                            {t("homeHeader")}
                          </Typography>
                          <Typography sx={classes.welcomeText} pt={4}>
                            {t("homeContent")}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container maxWidth="lg">
                    <Grid
                      container
                      pt={{ sm: 7, xs: 4 }}
                      justifyContent={"center"}
                    >
                      {features.map((feature) => {
                        return (
                          <Grid item xl={4} lg={4} md={6} sm={12} xs={12} p={1}>
                            <LightTooltip
                              placement="top"
                              arrow
                              sx={{
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "#ffffff",
                                  fontSize: "16px",
                                  color: "#000000",
                                },
                              }}
                              title={t(feature.tooltip)}
                            >
                              <Box sx={classes.cardStyle}>
                                <Box py={2} px={2} display={"flex"}>
                                  <img
                                    src={feature.icon}
                                    alt="Module not found..."
                                    style={{ width: "auto" }}
                                  />
                                  <Typography sx={classes.headerText}>
                                    {t(feature.header)}
                                  </Typography>
                                </Box>
                              </Box>
                            </LightTooltip>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Stack
                      direction={{ md: "row", xs: "column" }}
                      justifyContent={"center"}
                      spacing={2}
                      pt={{ sm: 7, xs: 4 }}
                      pb={5}
                    >
                      <Button
                        sx={classes.signupBtn}
                        onClick={() => {
                          history.push(urls.registerViewPath);
                        }}
                      >
                        {t("signup")}
                      </Button>
                      <Button
                        sx={classes.loginBtn}
                        onClick={() => {
                          history.push(urls.loginViewPath);
                        }}
                      >
                        {t("login")}
                      </Button>
                    </Stack>
                  </Container>
                </Stack>
                <Container maxWidth="lg">
                  <Box sx={classes.instructionBox}>
                    <Typography sx={classes.instructions}>
                      {t("userGuild")}
                    </Typography>
                  </Box>
                </Container>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getHome = () => {
    return (
      <>
        <AppHeader />
        <Box>{getContent()}</Box>
        <AppFooter />
      </>
    );
  };

  if (isAuthenticated) {
    if (
      props.location?.pathname?.split("/")[1].toLowerCase() !==
      strings.RESET_PASSWORD
    ) {
      history.push(urls.dashboardViewPath);
      return null;
    }
  }
  return getHome();
};

export default Home;
