import {
  Box,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import mailIcon from "../../assets/icons/mailIcon.svg";
import hidePasswordIcon from "../../assets/images/Hide.svg";
import showPasswordIcon from "../../assets/images/Show.svg";
import { requestForToken } from "../../firebase";
import {
  CustomButton,
  CustomDialog,
  CustomInput,
} from "../../global/components";
import strings from "../../global/constants/StringConstants";
import urls from "../../global/constants/UrlConstants";
import {
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "../../helpers/methods";
import {
  firebaseTokenAction,
  loginAction,
  selectFirebaseToken,
} from "../../redux/authSlice";
import history from "../../utils/history";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { setNotificationsToken } from "../Dashboard/DashboardService";
import loginStyles from "./Auth.styles";
import { login, resetPassword, setLanguagePreference } from "./AuthService";
import { getRole, loginForm, loginValidation } from "./AuthTypesAndValidation";

const Login = () => {
  const classes = loginStyles;
  const emailRegex = strings.regex;
  const dispatch = useAppDispatch();
  const firebaseToken = useAppSelector(selectFirebaseToken);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState(loginForm);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleLogin = async () => {
    try {
      if (handleValidation()) {
        setIsLoading(true);
        const email = formFields.email.value.toLowerCase();
        const password = formFields.password.value;
        const response = await login(email, password);
        const role = getRole(email);
        dispatch(
          loginAction({
            email,
            authenticated: true,
            accessToken: response.accessToken,
            firstName: response.firstName,
            lastName: response.lastName,
            role: role,
            isSubscribed: response.isSubscribed,
            isVedazUser: response.isVedazUser,
            profileCount: response.maxProfiles,
            profileId: response.defaultProfileId,
          })
        );
        const language = i18n.language.toLowerCase();
        await setLanguagePreference(language);
        !firebaseToken && setClientToken();
        setIsLoading(false);
        history.push(
          response.defaultProfileId && response.isSubscribed
            ? `${urls.analysisViewPath}?id=${response.defaultProfileId}`
            : urls.dashboardViewPath
        );
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const setClientToken = async () => {
    try {
      const token = await requestForToken();
      dispatch(
        firebaseTokenAction({
          firebaseToken: token,
        })
      );
      await setNotificationsToken(token);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleResetPassword = async () => {
    try {
      // setIsLoading(true);
      const response = await resetPassword(formFields.forgotEmail.value);
      openSuccessNotification(response.message);
      handleClose();
      // setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const handleValidation = () => {
    const { isValid, errors } = loginValidation(formFields);
    setFormFields({ ...errors });
    return isValid;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getLoginScreen = () => {
    return (
      <Box sx={classes.getLoginScreen}>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={"space-between"}
        >
          <Typography sx={classes.getHeading}> {t("login")}</Typography>
          <Typography
            sx={classes.getHeading2}
            onClick={() => {
              history.push(urls.registerViewPath);
            }}
          >
            {t("signup")}
          </Typography>{" "}
        </Stack>

        <Typography pt={1} sx={classes.welcomeTypo}>
          {t("welcome")}
        </Typography>
        <Box sx={classes.innerGetLoginBox} pt={{ sm: 7, xs: 5 }}>
          <CustomInput
            required
            label={t("email")}
            placeHolder={t("emailPlace")}
            id="email"
            type="email"
            name="email"
            value={formFields.email.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            error={!isTruthy(formFields.email.value) && formFields.email.error}
          />
          {!emailRegex.test(formFields.email.value) &&
            formFields.email.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
          <Box mt={2}>
            <CustomInput
              required
              label={t("password")}
              placeHolder="••••••••"
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formFields.password.value}
              onChange={handleOnChangeInputField}
              onKeyPress={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <Box
                        component="img"
                        src={showPassword ? showPasswordIcon : hidePasswordIcon}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                !isTruthy(formFields.password.value) &&
                formFields.password.error
              }
            />
          </Box>
          <Box
            textAlign={"end"}
            pt={1}
            sx={{ cursor: "pointer" }}
            onClick={() => setOpen(true)}
          >
            <Typography sx={classes.labelText}>
              {t("forgotPassword")}
            </Typography>
          </Box>
          <Box marginTop={"50px"} width={"100%"}>
            <CustomButton
              label={t("login")}
              type={strings.PRIMARY}
              loading={isLoading}
              onClick={handleLogin}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.lightTypo} px={2}>
          {t("forgotPasswordHeader")}
        </Typography>
        <Divider
          sx={{
            border: "1px solid #E7E7E7",
            width: "80%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} pb={5}>
        <Box mt={2} mb={5} width={"80%"}>
          <Box sx={classes.label} mb={1} borderColor="red">
            <Typography sx={classes.dialogSubTypo}>{t("email")} </Typography>
            <Typography sx={classes.star}>*</Typography>
          </Box>
          <TextField
            sx={classes.emailTextField}
            placeholder={t("emailPlace")}
            id="forgotEmail"
            name="forgotEmail"
            value={formFields.forgotEmail.value}
            onChange={handleOnChangeInputField}
            onKeyPress={handleKeypress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box component="img" src={mailIcon} />
                </InputAdornment>
              ),
            }}
          />
          {!isTruthy(formFields.forgotEmail.value) &&
            formFields.forgotEmail.error && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
          {!emailRegex.test(formFields.forgotEmail.value) &&
            formFields.forgotEmail.value.length > 0 && (
              <FormHelperText error sx={classes.errorStyling}>
                Please enter valid email id
              </FormHelperText>
            )}
        </Box>
        <CustomButton
          label={t("submit")}
          type={strings.PRIMARY}
          onClick={handleResetPassword}
          customClasses={{ maxWidth: "250px" }}
        />
      </Stack>
    );
  };

  const getForgotPasswordDialog = () => {
    return (
      <CustomDialog
        width="600px"
        borderRadius="10px"
        isDialogOpen={open}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  return (
    <Box px={1} sx={classes.scrollElement}>
      {getLoginScreen()}
      {getForgotPasswordDialog()}
    </Box>
  );
};

export default Login;
