import {
  centerItemFlex,
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";

const vedazTalkStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    m: 2,
    [theme.breakpoints.down("sm")]: {
      mt: 3,
    },
  },
  mainBox: {
    p: 1,
    borderRadius: "28px",
    height: "calc(100vh - 170px)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    maxWidth: "1400px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 160px)",
    },
  },
  subBox: {
    height: "calc(100vh - 172px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 162px)",
    },
  },
  scrollStyle: {
    height: "calc(100vh - 285px)",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "10px",
    pb: 0,
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      border: "1px solid #FFFFFF",
      background: "#282945",
    },
  },
  header: {
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "rgba(255, 255, 255, 0.14)",
    backdropSFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    px: 3,
    alignItems: "center",
    height: "50px",
    justifyContent: "space-between",
    display: "flex",
  },
  username: {
    ...regularFont,
    fontSize: getRelativeFontSize(7),
    color: "#FFFFFF",
    height: "100%",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  textField: {
    width: "100%",
    borderRadius: "0px",
    ".MuiOutlinedInput-notchedOutline": { border: "none !important" },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiInputBase-input": {
      position: "relative",
      padding: "12px 12px",
      color: "#FFFFFF",
      borderRadius: "0px",
      fontSize: getRelativeFontSize(4),
      "&::-webkit-scrollbar": {
        width: "0px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        border: "1px solid #FFFFFF",
        background: "#282945",
      },
      "&::placeholder": {
        ...mediumFont,
      },
    },
    "& .MuiOutlinedInput-root": {
      borderBottomRightRadius: "20px",
      borderBottomLeftRadius: "20px",
      padding: "0px 12px !important",
      "&.Mui-focused fieldset": {
        border: "none !important",
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontSize: getRelativeFontSize(1),
      ...mediumFont,
    },
  },
  messageBox: {
    display: "flex",
    marginBottom: "10px",
  },
  messageBoxRight: {
    justifyContent: "flex-end",
    pr: 2,
  },
  messageBoxLeft: {
    justifyContent: "flex-start",
    pl: 2,
  },
  message: {
    padding: "10px",
    borderRadius: "10px",
    maxWidth: "60%",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    position: "relative",
    paddingRight: "95px",
  },
  messageRight: {
    backgroundColor: "#8a58cb",
    alignSelf: "flex-end",
  },
  messageLeft: {
    background: "rgba(255, 255, 255, 0.14)",
    backdropSFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    alignSelf: "flex-start",
  },
  messageText: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#FFFFFF",
  },
  messageTime: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    fontSize: "0.75rem",
    color: "#c0c0c0",
    position: "absolute",
    bottom: "5px",
    right: "10px",
  },
  dateBox: {
    p: 0.5,
    ...centerItemFlex,
    justifySelf: "center",
    width: "fit-content",
    borderRadius: "8px",
    background: "#222222",
    marginBottom: "10px",
  },
  dateSeparator: {
    ...regularFont,
    textAlign: "center",
    color: "#ffffff",
    fontSize: getRelativeFontSize(0),
  },
  startChatMessage: {
    textAlign: "center",
    color: "#888",
    fontSize: getRelativeFontSize(3),
    marginTop: "20px",
  },
  typingIndicator: {
    color: "#65676B",
    fontSize: "18px",
    fontStyle: "italic",
    marginBottom: "10px",
    animation: "fadeInOut 1s infinite",
  },
  footer: {
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    background: "rgba(255, 255, 255, 0.14)",
    backdropSFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    width: "calc(100% - 19px)",
    position: "fixed",
    bottom: "8px",
    right: 0,
    display: "flex",
    justifyContent: "start",
    zIndex: 10,
    mx: 1,
  },
  newMessageDivider: {
    display: "flex",
    alignItems: "center",
    margin: "16px 0",
    "&::before, &::after": {
      content: '""',
      flex: 1,
      borderBottom: "1px solid #e0e0e0",
    },
  },
  newMessageText: {
    padding: "0 10px",
    color: "#ff4444",
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  welcomeBoxWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 172px)",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      border: "1px solid #FFFFFF",
      background: "#282945",
    },
  },
  welcomeBox: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    gap: 3,
    p: 4,
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      p: 2,
    },
  },
  chatImage: {
    width: "200px",
    height: "200px",
    opacity: 0.7,
    mb: 2,
    [theme.breakpoints.down("sm")]: {
      width: "120px",
      height: "120px",
    },
  },
  welcomeHeader: {
    ...mediumFont,
    color: "#ffffff90",
    fontSize: "30px",
    textAlign: "center",
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  selectUserText: {
    ...regularFont,
    color: "#ffffff70",
    fontSize: "22px",
    textAlign: "center",
    maxWidth: "80%",
    lineHeight: 1.6,
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  preferenceBtn: {
    backgroundColor: "rgba(138, 49, 255, 0.15)",
    color: "#ffffff",
    borderRadius: "12px",
    padding: "12px 24px",
    transition: "all 0.3s ease",
    border: "1px solid rgba(138, 49, 255, 0.3)",
    "&:hover": {
      backgroundColor: "rgba(138, 49, 255, 0.25)",
      transform: "scale(1.05)",
    },
  },
  preferenceBtnText: {
    ...regularFont,
    fontSize: "18px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
} as const;

const keyframes = `
  @keyframes fadeInOut {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
  }
`;

const styleSheet = document.styleSheets[0];
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default vedazTalkStyles;
