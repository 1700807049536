import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import strings from "../../../global/constants/StringConstants";
import { generateMenu } from "../../../utils/AuthorizationManager";
import { theme } from "../../../utils/styles";
import appDrawerStyles from "./AppDrawer.styles";
import UsersDrawerItem from "./UsersDrawerItem";

interface CustomProps {
  setMenuMobileVisible?: Function;
  isActive?: boolean;
  location?: Location;
}

const AppDrawer = (props: CustomProps) => {
  const classes = appDrawerStyles;
  const { t } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState<any>({
    Category: false,
    "Sub Category": false,
  });
  const [optionItems, setOptionItems] = useState<any[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const location = window.location.pathname.split("/")[1].toLowerCase();
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 900) {
        setIsDrawerOpen(false);
      } else {
        setIsDrawerOpen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    generateAppDrawer();
  }, []);

  useEffect(() => {
    if (location === strings.VEDAZ_TALK && isTablet) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          drawerRef.current &&
          !drawerRef.current.contains(event.target as Node)
        ) {
          setIsDrawerOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [location, isTablet]);

  const generateAppDrawer = () => {
    setOptionItems(generateMenu());
  };

  const isActiveTab = (pathName: string, key: string) => {
    const currentPath = window.location.pathname.toLowerCase();
    return (
      currentPath === pathName.toLowerCase() ||
      currentPath.includes(key.toLowerCase())
    );
  };

  const handleSubMenuClick = (pageName: string) => {
    setOpen({
      ...open,
      [pageName]: !open[pageName],
    });
  };

  const handleClick = (option: any) => {
    if (!option.subMenu) {
      const updatedOpenState: any = {};
      Object.keys(open).forEach((key) => {
        updatedOpenState[key] = false;
      });
      setOpen(updatedOpenState);
    }
    isTablet && setIsDrawerOpen(false);
  };

  const getMenuOptions = (option: any, index: number) => {
    return (
      <Box key={index}>
        <NavLink
          to={!option.subMenu ? option.key : "#"}
          onClick={() => handleClick(option)}
          style={
            isTablet
              ? { ...classes.menuOption, marginTop: "10px" }
              : classes.menuOption
          }
        >
          {option.subMenu ? (
            <>
              <ListItem
                onClick={() => handleSubMenuClick(option.pageName)}
                sx={classes.menuItems}
              >
                <ListItemIcon sx={classes.listItemIconStyle}>
                  {isActiveTab(option.key, option.pageName) ? (
                    <img src={option.activeIcon} />
                  ) : (
                    <img src={option.icon} />
                  )}
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={classes.navBarLabel}>
                    {t(option.text)}
                  </Typography>
                </ListItemText>
                {open[option.pageName] ? <ChevronLeft /> : <ChevronRight />}
              </ListItem>
              <Collapse
                in={open[option.pageName]}
                sx={{ background: "#2c3b41" }}
              >
                {option.subMenu.map((data: any, index: number) => {
                  return (
                    <NavLink
                      to={data.key}
                      style={(isActive) =>
                        isActive
                          ? classes.selectedMenuOptionListItem
                          : classes.menuOptionListItem
                      }
                    >
                      <ListItem
                        sx={classes.menuItems}
                        onClick={() => handleSubMenuClick(option.pageName)}
                      >
                        <ListItemIcon
                          sx={classes.listItemIconStyle}
                        ></ListItemIcon>
                        <ListItemText>
                          <Typography sx={classes.navBarLabel}>
                            {data.text}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  );
                })}
              </Collapse>
            </>
          ) : (
            <ListItem sx={classes.menuItems}>
              <ListItemIcon sx={classes.listItemIconStyle}>
                {isActiveTab(option.key, option.pageName) ? (
                  <img src={option.activeIcon} />
                ) : (
                  <img src={option.icon} />
                )}
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={
                    isActiveTab(option.key, option.pageName)
                      ? classes.selectedNavBarLabel
                      : classes.navBarLabel
                  }
                >
                  {t(option.text)}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
        </NavLink>
      </Box>
    );
  };

  const getNewMenuOptions = () => {
    return (
      <>
        <Box
          onClick={() => {
            setIsDrawerOpen(!isDrawerOpen);
          }}
          sx={[
            classes.drawerIconBox,
            isDrawerOpen
              ? classes.openDrawerIconBox
              : classes.closedDrawerIconBox,
          ]}
        >
          {isDrawerOpen ? (
            <ArrowBackIcon fontSize="small" htmlColor="#ffffff" />
          ) : (
            <ArrowForwardIcon fontSize="small" htmlColor="#ffffff" />
          )}
        </Box>
        {location !== strings.VEDAZ_TALK && (
          <List dense>
            {optionItems.map((option, index) => {
              return <Box>{getMenuOptions(option, index)}</Box>;
            })}
          </List>
        )}
        {location === strings.VEDAZ_TALK && <UsersDrawerItem />}
      </>
    );
  };

  const getAppDrawer = () => {
    return (
      <>
        <Box
          ref={drawerRef}
          sx={
            !isDrawerOpen
              ? {
                  ...classes.drawer,
                  width: "0px",
                  transition: "width 0.5s ease",
                }
              : classes.drawer
          }
        >
          <Box
            sx={
              !isDrawerOpen
                ? {
                    ...classes.drawerWidth,
                    width: "0px",
                    transition: "width 0.5s ease",
                  }
                : classes.drawerWidth
            }
          >
            <Box sx={classes.menuOptionsHeight}>{getNewMenuOptions()}</Box>
          </Box>
        </Box>
      </>
    );
  };

  return getAppDrawer();
};

export default AppDrawer;
