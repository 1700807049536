import backImage from "../assets/images/loginBackImage.png";
import { getRelativeFontSize, mediumFont } from "./styles";

const errorBoundaryStyle = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    background: `url(${backImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#1c0d2c",
  },
  contentBox: {
    p: 3,
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(12px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "20px",
  },
  errorIcon: {
    fontSize: 64,
    color: "#ff3d00",
    mb: 2,
  },
  heading: {
    color: "#ffffff",
    ...mediumFont,
    fontSize: getRelativeFontSize(12),
    textAlign: "center",
    mb: 2,
  },
  subText: {
    color: "rgba(255, 255, 255, 0.7)",
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    textAlign: "center",
    mb: 4,
  },
  refreshBtn: {
    py: 1,
    borderRadius: "8px",
    background: "#FDFDFD",
    color: "#9053F6",
    ...mediumFont,
    fontSize: getRelativeFontSize(6),
    minWidth: "180px",
    textTransform: "none",
    "&:hover": {
      boxShadow: "0px 0.1em 0.2em rgb(45 35 66 / 40%)",
      transform: "translateY(-0.1em)",
      background: "#FDFDFD",
    },
  },
  logoutBtn: {
    py: 1,
    borderRadius: "8px",
    background: "#9053F6",
    color: "#FDFDFD",
    ...mediumFont,
    fontSize: getRelativeFontSize(6),
    minWidth: "180px",
    textTransform: "none",
    "&:hover": {
      boxShadow: "0px 0.1em 0.2em rgb(45 35 66 / 40%)",
      transform: "translateY(-0.1em)",
      background: "#9053F6",
    },
  },
} as const;

export default errorBoundaryStyle;
