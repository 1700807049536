import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomTable } from "../../../../../global/components";
import PieChart from "./PieChart";

interface CustomProps {
  chartTitle: string;
  chartData: any;
  chartSlices: any;
  tableHeader1: any;
  tableData1: any;
  tableHeader2: any;
  tableData2: any;
  tableHeader3: any;
  tableData3: any;
  placeColor?: string;
}

const NakshatraAnalysis = (props: CustomProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction={"column"} mt={2} spacing={3}>
      <PieChart
        data={props.chartData}
        title={t(props.chartTitle)}
        slices={props.chartSlices}
        maxData={props.tableData3}
        placeColor={props.placeColor}
      />
      <Box sx={{ overflowX: "auto" }}>
        <CustomTable
          headers={props.tableHeader1}
          rows={props.tableData1}
          isRowPerPageEnable={true}
          rowsPerPage={20}
        />
      </Box>
      <Box sx={{ overflowX: "auto" }}>
        <CustomTable
          headers={props.tableHeader2}
          rows={props.tableData2}
          isRowPerPageEnable={true}
        />
      </Box>
      <Box sx={{ overflowX: "auto" }}>
        <CustomTable
          headers={props.tableHeader3}
          rows={props.tableData3}
          isRowPerPageEnable={true}
        />
      </Box>
    </Stack>
  );
};

export default NakshatraAnalysis;
