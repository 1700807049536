import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const getPreference = async (email: string) => {
  try {
    const callParams = await getCallParams("POST", { email });
    const response = await makeCall(urls.getPreference, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updatePreference = async (preference: Preference) => {
  try {
    const callParams = await getCallParams("POST", preference);
    const response = await makeCall(urls.updatePreference, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export interface Preference {
  email: string;
  newPrefs: {
    ageFrom: number;
    ageTo: number;
    latitude: number;
    longitude: number;
    cityKmRange: number;
    city: string;
    sex: string;
  };
}
