import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, CustomDialog } from "../../../../global/components";
import CustomInput from "../../../../global/components/CustomInput/CustomInput";
import CustomSelect from "../../../../global/components/CustomSelect/CustomSelect";
import strings from "../../../../global/constants/StringConstants";
import {
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "../../../../helpers/methods";
import {
  selectUserEmail,
  setChatLoadingAction,
  setChatUsersAction,
  setReceiverUsersAction,
  setTimezoneAction,
} from "../../../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  getPreference,
  updatePreference,
} from "../../../VedazTalk/VedazTalkService";
import { compatibilityAllUser } from "../../AppDrawer/UserDrawerItemService";
import contactUsDialogStyles from "../ContactUsDialog/ContactUsDialog.styles";

declare global {
  interface Window {
    google: {
      maps: {
        places: {
          Autocomplete: new (
            input: HTMLInputElement,
            options?: any
          ) => google.maps.places.Autocomplete;
        };
        event: {
          addListener: (
            instance: any,
            eventName: string,
            handler: Function
          ) => void;
          removeListener: (listener: any) => void;
        };
      };
    };
  }
}

interface CustomProps {
  openPreference: boolean;
  handleClose: Function;
  setOpenPreference: Function;
  setIsLoading: Function;
}

interface Address {
  latitude: string;
  longitude: string;
  location: string;
}

const PreferencesDialog = (props: CustomProps) => {
  const classes = contactUsDialogStyles;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loginUser = useAppSelector(selectUserEmail);
  const [preferences, setPreferences] = useState({
    ageFrom: {
      value: "",
      error: "",
    },
    ageTo: {
      value: "",
      error: "",
    },
    cityKmRange: {
      value: "",
      error: "",
    },
    birthPlace: {
      value: "",
      error: "",
    },
    sex: {
      value: "",
      error: "",
    },
  });
  const [address, setAddress] = useState<Address>({
    latitude: "",
    longitude: "",
    location: "",
  });

  const autocompleteRef = useRef<any>(null);
  const autocompleteListenerRef = useRef<any>(null);

  const genderMenu = [
    { value: "Male", content: `${t("man")}` },
    { value: "Female", content: `${t("woman")}` },
  ];

  useEffect(() => {
    if (address.location) {
      setPreferences({
        ...preferences,
        birthPlace: {
          ...preferences.birthPlace,
          value: address.location,
        },
      });
    } else if (!preferences.birthPlace.value) {
      setAddress({
        ...address,
        location: "",
      });
    }
  }, [address.location]);

  useEffect(() => {
    const visitedUsers = JSON.parse(
      localStorage.getItem("visitedUsers") || "[]"
    );
    if (!visitedUsers.includes(loginUser)) {
      getPreferenceData();
    }
    return () => {
      if (autocompleteListenerRef.current) {
        window.google?.maps?.event?.removeListener(
          autocompleteListenerRef.current
        );
        autocompleteListenerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (props.openPreference) {
      const visitedUsers = JSON.parse(
        localStorage.getItem("visitedUsers") || "[]"
      );
      if (visitedUsers.includes(loginUser)) {
        getPreferenceData();
      }
      const initTimeout = setTimeout(() => {
        initializeAutocomplete();
      }, 300);

      return () => {
        clearTimeout(initTimeout);
      };
    }
  }, [props.openPreference]);

  const handlePlaceChanged = (autocomplete: any) => {
    try {
      const place = autocomplete.getPlace();
      if (place && place.geometry && place.geometry.location) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setAddress({
          latitude: latitude.toString(),
          longitude: longitude.toString(),
          location: place.formatted_address || "",
        });
      }
    } catch (error) {
      console.error("Error handling place change:", error);
    }
  };

  const initializeAutocomplete = () => {
    try {
      const input = document.getElementById("birthPlace") as HTMLInputElement;
      if (
        input &&
        window.google &&
        window.google.maps &&
        window.google.maps.places
      ) {
        if (autocompleteListenerRef.current) {
          window.google.maps.event.removeListener(
            autocompleteListenerRef.current
          );
          autocompleteListenerRef.current = null;
        }

        autocompleteRef.current = new window.google.maps.places.Autocomplete(
          input,
          {
            types: ["(cities)"],
          }
        );

        autocompleteListenerRef.current = window.google.maps.event.addListener(
          autocompleteRef.current,
          "place_changed",
          () => handlePlaceChanged(autocompleteRef.current)
        );

        setTimeout(() => {
          const pacContainer = document.querySelector(".pac-container");
          if (pacContainer) {
            (pacContainer as HTMLElement).style.zIndex = "1500";
          }
        }, 100);
      } else {
        console.error("Google Maps API not loaded correctly");
      }
    } catch (error) {
      console.error("Error initializing autocomplete:", error);
    }
  };

  const getPreferenceData = async () => {
    try {
      const visitedUsers = JSON.parse(
        localStorage.getItem("visitedUsers") || "[]"
      );
      if (!visitedUsers.includes(loginUser)) {
        dispatch(setChatLoadingAction({ chatLoading: true }));
      } else {
        props.setIsLoading(true);
      }

      const response = await getPreference(loginUser);
      setPreferences({
        ageFrom: {
          value: response.ageFrom,
          error: "",
        },
        ageTo: {
          value: response.ageTo,
          error: "",
        },
        cityKmRange: {
          value: response.cityKmRange,
          error: "",
        },
        birthPlace: {
          value: response.city,
          error: "",
        },
        sex: {
          value: response.sex,
          error: "",
        },
      });

      if (response.city && response.latitude && response.longitude) {
        setAddress({
          latitude: response.latitude.toString(),
          longitude: response.longitude.toString(),
          location: response.city,
        });
      }
      if (!visitedUsers.includes(loginUser)) {
        props.setOpenPreference(true);
        dispatch(setChatLoadingAction({ chatLoading: false }));
        visitedUsers.push(loginUser);
        localStorage.setItem("visitedUsers", JSON.stringify(visitedUsers));
      } else {
        props.setIsLoading(false);
      }
    } catch (error: any) {
      props.setIsLoading(false);
      dispatch(setChatLoadingAction({ chatLoading: false }));
      handleErrorMessage(error);
    }
  };

  const validateFields = (): boolean => {
    let isValid = true;
    const newPreferences = { ...preferences };

    if (!preferences.birthPlace.value) {
      newPreferences.birthPlace.error = t("cityRequired");
      isValid = false;
    }

    if (!preferences.cityKmRange.value) {
      newPreferences.cityKmRange.error = t("rangeRequired");
      isValid = false;
    }

    if (!preferences.sex.value) {
      newPreferences.sex.error = t("sexRequired");
      isValid = false;
    }

    if (!preferences.ageFrom.value) {
      newPreferences.ageFrom.error = t("ageFromRequired");
      isValid = false;
    }

    if (!preferences.ageTo.value) {
      newPreferences.ageTo.error = t("ageToRequired");
      isValid = false;
    }

    setPreferences(newPreferences);
    return isValid;
  };

  const updatePreferenceData = async () => {
    try {
      if (!validateFields()) {
        return;
      }

      const obj = {
        email: loginUser,
        newPrefs: {
          ageFrom: +preferences.ageFrom.value,
          ageTo: +preferences.ageTo.value,
          cityKmRange: +preferences.cityKmRange.value,
          city: preferences.birthPlace.value,
          sex: preferences.sex.value,
          latitude: address.latitude ? +address.latitude : 0,
          longitude: address.longitude ? +address.longitude : 0,
        },
      };
      dispatch(setChatLoadingAction({ chatLoading: true }));
      const response = await updatePreference(obj);
      openSuccessNotification(response.message);
      props.setOpenPreference(false);
      getCompatibilityAllUser();
    } catch (error: any) {
      dispatch(setChatLoadingAction({ chatLoading: false }));
      handleErrorMessage(error);
    }
  };

  const getCompatibilityAllUser = async () => {
    try {
      const response = await compatibilityAllUser(loginUser);
      dispatch(
        setChatUsersAction({ chatUsers: response.compatibilityResults })
      );
      dispatch(
        setReceiverUsersAction({ receiverUsers: response.receiverResults })
      );
      dispatch(setTimezoneAction({ timezone: response.timezone }));
      dispatch(setChatLoadingAction({ chatLoading: false }));
    } catch (error: any) {
      dispatch(setChatLoadingAction({ chatLoading: false }));
      handleErrorMessage(error);
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let newValue = value;

    switch (name) {
      case "cityKmRange":
        const kmValue = parseInt(value);
        if (!isNaN(kmValue)) {
          newValue = Math.min(Math.max(kmValue, 0), 10000).toString();
        }
        break;

      case "ageFrom":
        const ageFromValue = parseInt(value);
        if (!isNaN(ageFromValue)) {
          let validAge = Math.min(Math.max(ageFromValue, 1), 150);
          if (preferences.ageTo.value) {
            validAge = Math.min(
              validAge,
              parseInt(preferences.ageTo.value) - 1
            );
          }
          newValue = validAge.toString();
        }
        break;

      case "ageTo":
        const ageToValue = parseInt(value);
        if (!isNaN(ageToValue)) {
          let validAge = Math.min(Math.max(ageToValue, 1), 150);
          if (preferences.ageFrom.value) {
            validAge = Math.max(
              validAge,
              parseInt(preferences.ageFrom.value) + 1
            );
          }
          newValue = validAge.toString();
        }
        break;
    }

    setPreferences({
      ...preferences,
      [name]: {
        ...preferences[name as keyof typeof preferences],
        value: newValue,
        error: "",
      },
    });

    if (name === "birthPlace" && value === "") {
      setAddress({
        latitude: "",
        longitude: "",
        location: "",
      });
    }
  };

  const dialogHeaderContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2} pt={5}>
        <Typography sx={classes.contactUsHeader} px={2}>
          {t("preferenceHeader")}
        </Typography>
        <Divider
          sx={{
            border: "1px solid #222222",
            width: "50%",
            height: "0px",
          }}
        />
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack
        direction={"column"}
        alignItems={"center"}
        pb={5}
        spacing={2}
        sx={classes.dialogContent}
      >
        <Grid
          container
          spacing={{ sm: 2, xs: 0 }}
          rowGap={{ sm: 0, xs: 1 }}
          columnGap={{ sm: 0, xs: 1 }}
        >
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomInput
              label={t("city")}
              placeHolder={t("cityPlace")}
              id="birthPlace"
              type="text"
              name="birthPlace"
              customClasses={classes.textField}
              customNameFieldClasses={classes.nameField}
              value={preferences.birthPlace.value}
              onChange={handleFieldChange}
              error={
                !isTruthy(
                  preferences.birthPlace.value &&
                    address.latitude &&
                    address.longitude
                ) && preferences.birthPlace.error
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomInput
              label={t("range")}
              name="cityKmRange"
              id="cityKmRange"
              placeHolder={t("rangePlace")}
              customClasses={classes.textField}
              customNameFieldClasses={classes.nameField}
              value={preferences.cityKmRange.value}
              onChange={handleFieldChange}
              error={
                !isTruthy(preferences.cityKmRange.value) &&
                preferences.cityKmRange.error
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomSelect
              menuItems={genderMenu}
              label={t("sex")}
              id={"sex"}
              name={"sex"}
              customNameFieldClasses={classes.nameField}
              customClasses={classes.textField}
              onChange={handleFieldChange}
              value={preferences.sex.value}
              error={preferences.sex.error}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={5.8}>
            <CustomInput
              label={t("age")}
              name="ageFrom"
              id="ageFrom"
              placeHolder={t("ageFrom")}
              customNameFieldClasses={classes.nameField}
              value={preferences.ageFrom.value}
              customClasses={classes.textField}
              onChange={handleFieldChange}
              error={
                !isTruthy(preferences.ageFrom.value) &&
                preferences.ageFrom.error
              }
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={5.8} mb={3}>
            <CustomInput
              label={t("ageTo")}
              name="ageTo"
              id="ageTo"
              placeHolder={t("ageTo")}
              value={preferences.ageTo.value}
              customClasses={classes.textField}
              onChange={handleFieldChange}
              error={
                !isTruthy(preferences.ageTo.value) && preferences.ageTo.error
              }
            />
          </Grid>
        </Grid>

        <CustomButton
          label={t("update")}
          type={strings.PRIMARY}
          onClick={updatePreferenceData}
          customClasses={{ maxWidth: "250px" }}
        />
      </Stack>
    );
  };

  const getPreferencesDialog = () => {
    return (
      <CustomDialog
        width="900px"
        borderRadius="10px"
        isDialogOpen={props.openPreference}
        closable={true}
        closeButtonVisibility={true}
        handleDialogClose={props.handleClose}
        dialogHeaderContent={dialogHeaderContent()}
        dialogBodyContent={dialogBodyContent()}
      />
    );
  };

  return getPreferencesDialog();
};

export default PreferencesDialog;
