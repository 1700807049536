import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SendIcon from "@mui/icons-material/Send";
import WcIcon from "@mui/icons-material/Wc";
import {
  Box,
  IconButton,
  InputAdornment,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Socket } from "socket.io-client";
import { CustomIcon, CustomInput } from "../../global/components";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import {
  selectChatLoading,
  selectChatUsers,
  selectReceiverUsers,
  selectSelectedUser,
  selectTimezone,
  selectUnreadCounts,
  selectUserEmail,
  setUnreadCountsAction,
} from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { centerItemFlex } from "../../utils/styles";
import PreferencesDialog from "../Shared/Dialogs/PreferenceDialog/PreferenceDialog";
import vedazTalkStyles from "./VedazTalk.styles";
import WelcomeScreen from "./WelcomeScreen";
import {
  convertToTimezone,
  formatDate,
  generateMessageId,
  generateTimestamp,
  getRandomWidth,
} from "./utils";

type MessageStatus = "sent" | "delivered" | "read";

interface ChatContainer {
  type: string;
  target: string;
  message: {
    messages: Chat[];
    date: string;
  }[];
}

interface Chat {
  content: string;
  sender: string;
  timestamp: string;
  status: MessageStatus;
  id: number;
}

const VedazTalk = () => {
  const classes = vedazTalkStyles;
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(selectSelectedUser) ?? { username: "" };
  const loginUser = useAppSelector(selectUserEmail);
  const chatLoading = useAppSelector(selectChatLoading);
  const chatUsers = useAppSelector(selectChatUsers);
  const receiverUsers = useAppSelector(selectReceiverUsers);
  const unreadCounts = useAppSelector(selectUnreadCounts);
  const timezone = useAppSelector(selectTimezone);
  const { t } = useTranslation();
  const [content, setContent] = useState<string>("");
  const [chat, setChat] = useState<ChatContainer>({
    type: "",
    target: "",
    message: [],
  });
  const [socket, setSocket] = useState<Socket | null | any>(null);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openPreference, setOpenPreference] = useState<boolean>(false);
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const selectedUserRef = useRef(selectedUser);

  useEffect(() => {
    selectedUserRef.current = selectedUser;
  }, [selectedUser]);

  useEffect(() => {
    scrollToBottom();
  }, [chat, selectedUser.username, content]);

  useEffect(() => {
    setChat({
      target: "",
      type: "",
      message: [],
    });
  }, [selectedUser.username]);

  useEffect(() => {
    connectSocket();
  }, []);

  useEffect(() => {
    const username = selectedUser.username;
    if (socket && username) {
      if (socket.readyState === WebSocket.OPEN) {
        openChat(socket, username);
      } else {
        socket.onopen = () => {
          openChat(socket, username);
        };
      }
    }
  }, [selectedUser.username, socket]);

  useEffect(() => {
    if ((chatUsers.length > 0 || receiverUsers.length > 0) && socket) {
      socket.send(
        JSON.stringify({
          type: "get_unread_count",
          target_list: [
            ...chatUsers.map((user: any) => user.username),
            ...receiverUsers.map((user: any) => user.username),
          ],
        })
      );
    }
  }, [chatUsers, receiverUsers]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      closeSocket();
    };

    const handlePopState = () => {
      closeSocket();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [socket]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (isTabVisible && selectedUser.username) {
      markMessagesAsRead();
    }
  }, [isTabVisible, chat, selectedUser.username]);

  const handleVisibilityChange = () => {
    setIsTabVisible(!document.hidden);
  };

  const markMessagesAsRead = () => {
    const unreadMsgs = chat.message
      .flatMap((group) => group.messages)
      .filter(
        (msg) => msg.status !== "read" && msg.sender === selectedUser.username
      );

    if (unreadMsgs.length > 0) {
      const latestMessage = unreadMsgs[unreadMsgs.length - 1];

      socket.send(
        JSON.stringify({
          type: "get_update_status",
          id: latestMessage.id,
        })
      );
    }
  };

  const scrollToBottom = () => {
    const chatArea = document.querySelector(".scrollStyle");
    if (chatArea) {
      chatArea.scrollTop = chatArea.scrollHeight;
    }
  };

  const connectSocket = () => {
    const ws = new WebSocket("wss://vedaz.astroepigenetics.com");
    // const ws = new WebSocket(
    //   "wss://496d-2401-b200-2047-df3e-f88f-4327-8548-ea40.ngrok-free.app"
    // );
    ws.onopen = () => {
      console.log("Connected to WebSocket server");
      ws.send(
        JSON.stringify({
          nickname: loginUser,
        })
      );
    };

    ws.onmessage = (event) => handleMessage(event);

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    setSocket(ws);
  };

  const closeSocket = () => {
    if (socket) {
      socket.send(
        JSON.stringify({
          type: "disconnected",
        })
      );
      socket.close();
      setSocket(null);
      console.log("WebSocket connection closed from close");
    }
  };

  const openChat = (ws: any, user: string) => {
    ws.send(
      JSON.stringify({
        type: "get_history",
        target: user,
      })
    );
  };

  const handleMessage = (event: any) => {
    const message = JSON.parse(event.data);
    console.log("message", message);
    switch (message.type) {
      case "message":
        receiveMessage(
          {
            content: message.content,
            sender: message.sender,
            timestamp: message.timestamp,
            status: message.status,
            id: message.id,
          },
          message.receiver
        );
        break;
      case "chat_history":
        loadChatHistory(message);
        break;
      case "typing":
        handleTypingIndicator(message.sender, message.isTyping);
        break;
      case "update_status":
        const messageIds = Array.isArray(message.id)
          ? message.id
          : [message.id];
        updateMessageStatus(messageIds, "read");
        break;
      case "unread_count":
        dispatch(setUnreadCountsAction({ unreadCounts: message.message }));
        break;
      case "user_unread_count":
        updateUserReadCount(message);
        break;
      case "error":
        alert(message.content);
        break;
    }
  };

  const updateUserReadCount = (message: any) => {
    if (selectedUser.username === message.target) return;
    const updatedCounts = unreadCounts
      ? unreadCounts.some((count) => count.target === message.target)
        ? unreadCounts.map((unread) =>
            unread.target === message.target
              ? { ...unread, count: message.count }
              : unread
          )
        : [...unreadCounts, { target: message.target, count: message.count }]
      : [{ target: message.target, count: message.count }];

    dispatch(setUnreadCountsAction({ unreadCounts: updatedCounts }));
  };

  const updateMessageStatus = (ids: number[], newStatus: MessageStatus) => {
    const idSet = new Set(ids.map((id) => Number(id)));
    setChat((prevChat) => {
      let updatedCount = 0;
      const updatedChat = {
        ...prevChat,
        message: prevChat.message.map((group) => ({
          ...group,
          messages: group.messages.map((msg) => {
            if (idSet.has(Number(msg.id))) {
              updatedCount++;
              return { ...msg, status: newStatus };
            }
            return msg;
          }),
        })),
      };
      return updatedChat;
    });
  };

  const receiveMessage = (message: Chat, receiver: string) => {
    const currentSelectedUser = selectedUserRef.current;
    if (currentSelectedUser.username !== message.sender) {
      return;
    }

    const today = new Date().toISOString().split("T")[0];
    setChat((prevChat) => {
      const updatedChat = [...prevChat.message];
      const todayChat = updatedChat.find(
        (chatGroup) => chatGroup.date === today
      );

      if (todayChat) {
        const messageExists = todayChat.messages.some(
          (msg) => msg.id === message.id
        );

        if (!messageExists) {
          todayChat.messages.push(message);
        }
      } else {
        updatedChat.push({
          date: today,
          messages: [message],
        });
      }

      return {
        ...prevChat,
        message: updatedChat,
      };
    });
  };

  const loadChatHistory = (chatContainer: ChatContainer) => {
    setChat((prevChat) => {
      const updatedChat = [...prevChat.message];
      chatContainer.message.forEach((chatGroup) => {
        const existingChatGroup = updatedChat.find(
          (group) => group.date === chatGroup.date
        );

        if (existingChatGroup) {
          existingChatGroup.messages = [
            ...existingChatGroup.messages,
            ...chatGroup.messages,
          ];
        } else {
          updatedChat.push(chatGroup);
        }
      });

      return {
        ...prevChat,
        message: updatedChat,
      };
    });
  };

  const handleTypingIndicator = (sender: string, isTyping: boolean) => {
    if (sender !== selectedUser.username) return;

    setIsTyping(isTyping);
  };

  const handleSubmit = () => {
    sendMessage(content);
  };

  const sendMessage = async (messageContent: string) => {
    if (!messageContent.trim()) return;
    const timestamp = generateTimestamp();
    const messageId = generateMessageId(
      loginUser,
      selectedUser.username,
      timestamp
    );
    const newMessage: Chat = {
      content: messageContent,
      sender: loginUser,
      timestamp: timestamp,
      status: "sent",
      id: messageId,
    };

    const today = new Date().toISOString().split("T")[0];
    const updatedChat = [...chat.message];
    const todayChat = updatedChat.find((chatGroup) => chatGroup.date === today);

    if (todayChat) {
      todayChat.messages.push(newMessage);
    } else {
      updatedChat.push({
        date: today,
        messages: [newMessage],
      });
    }
    setChat({ ...chat, message: updatedChat });
    setContent("");

    socket.send(
      JSON.stringify({
        type: "message",
        target: selectedUser.username,
        content: messageContent,
        sender: loginUser,
        status: "sent",
        id: messageId,
      })
    );

    socket.send(
      JSON.stringify({
        type: "typing",
        target: selectedUser.username,
        isTyping: false,
      })
    );
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    } else {
      socket.send(
        JSON.stringify({
          type: "typing",
          target: selectedUser.username,
          isTyping: false,
        })
      );
    }
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setContent(event.target.value);
    socket.send(
      JSON.stringify({
        type: "typing",
        target: selectedUser.username,
        isTyping: true,
      })
    );
  };

  const getHeader = () => {
    return (
      <Box sx={classes.header}>
        {chatLoading ? (
          <Skeleton
            variant="rectangular"
            width={150}
            height={30}
            sx={{
              background: "rgb(255 255 255 / 50%)",
              borderRadius: "8px",
            }}
          />
        ) : (
          <Typography sx={classes.username}>
            {selectedUser.firstName + " " + selectedUser.lastName}
          </Typography>
        )}
        <Box
          gap={1}
          onClick={() => (chatLoading ? {} : setOpenPreference(true))}
          sx={{ ...centerItemFlex, cursor: "pointer" }}
        >
          <CustomIcon icon={<WcIcon htmlColor="#ffffff" />} />
          <Typography sx={classes.username}>{t("preference")}</Typography>
        </Box>
      </Box>
    );
  };

  const getChatSkeleton = () => {
    const skeletonChats = Array.from({ length: 10 }, (_, index) => (
      <Box
        key={index}
        sx={[
          classes.messageBox,
          index % 2 === 0 ? classes.messageBoxRight : classes.messageBoxLeft,
        ]}
      >
        <Skeleton
          variant="rectangular"
          width={getRandomWidth()}
          height={35}
          sx={{
            mb: "10px",
            background: "rgb(255 255 255 / 20%)",
            borderRadius: "8px",
          }}
        />
      </Box>
    ));

    return <>{skeletonChats}</>;
  };

  const getChatArea = () => {
    if (chatLoading) {
      return (
        <Box className="scrollStyle" sx={classes.scrollStyle}>
          {getChatSkeleton()}
        </Box>
      );
    }

    const hasMessages = chat.message.some(
      (chatGroup) => chatGroup.messages.length > 0
    );

    if (!hasMessages) {
      return (
        <Box className="scrollStyle" sx={classes.scrollStyle}>
          <Typography sx={classes.startChatMessage}>Start chat</Typography>
        </Box>
      );
    }

    return (
      <Box className="scrollStyle" sx={classes.scrollStyle}>
        {chat.message.map((chatGroup) => {
          return (
            <React.Fragment key={chatGroup.date}>
              <Box sx={classes.dateBox}>
                <Typography sx={classes.dateSeparator}>
                  {formatDate(chatGroup.date)}
                </Typography>
              </Box>
              {chatGroup.messages.map((item: Chat) => {
                const isUserMessage = item.sender === loginUser;
                const messageTime = convertToTimezone(item.timestamp, timezone);
                return (
                  <Box
                    sx={[
                      classes.messageBox,
                      isUserMessage
                        ? classes.messageBoxRight
                        : classes.messageBoxLeft,
                    ]}
                  >
                    <Box
                      sx={[
                        classes.message,
                        isUserMessage
                          ? classes.messageRight
                          : classes.messageLeft,
                      ]}
                    >
                      <Typography sx={classes.messageText}>
                        {item.content}
                        <Typography sx={classes.messageTime}>
                          {messageTime}
                          <MessageStatus
                            status={item.status}
                            isUserMessage={isUserMessage}
                          />
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </React.Fragment>
          );
        })}
        <Box ref={chatEndRef} />
        {/* {isTyping && (
          <Box ref={chatEndRef} sx={classes.typingIndicator}>
            Typing...
          </Box>
        )} */}
      </Box>
    );
  };

  const MessageStatus = ({
    status,
    isUserMessage,
  }: {
    status: MessageStatus;
    isUserMessage: boolean;
  }) => {
    if (!isUserMessage) return null;

    switch (status) {
      case "sent":
        return <DoneIcon sx={{ fontSize: 16, color: "#a5a5a5" }} />;
      case "delivered":
        return <DoneAllIcon sx={{ fontSize: 16, color: "#a5a5a5" }} />;
      case "read":
        return <DoneAllIcon sx={{ fontSize: 16, color: "#4FC3F7" }} />;
      default:
        return null;
    }
  };

  const getFooter = () => {
    return (
      <Box sx={classes.footer}>
        <CustomInput
          multiline
          rows={1}
          value={content}
          onChange={handleInput}
          onKeyPress={handleKeypress}
          placeHolder={"Write your message..."}
          customClasses={classes.textField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSubmit}
                  sx={{
                    background: "#8A31FF",
                    "&:hover": {
                      background: "#8a58cb",
                    },
                  }}
                >
                  <SendIcon htmlColor="#ffffff" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  const getVedazTalk = () => {
    return (
      <>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              {selectedUser.username ? (
                <>
                  {getHeader()}
                  {getChatArea()}
                  {getFooter()}
                </>
              ) : (
                <WelcomeScreen setOpenPreference={setOpenPreference} />
              )}
              <PreferencesDialog
                openPreference={openPreference}
                handleClose={() => setOpenPreference(false)}
                setOpenPreference={setOpenPreference}
                setIsLoading={setIsLoading}
              />
            </Box>
          </Box>
        </Box>
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };
  return getVedazTalk();
};

export default VedazTalk;
