import {
  boldFont,
  drawerWidth,
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../../utils/styles";

const appDrawerStyles = {
  appLogo: {
    background: "#FFFFFF",
    border: "1px solid #F5F5F5",
    boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.06)",
    height: "47px",
    borderRadius: "10px",
    "&:hover": {
      background: "#ffffff",
    },
  },
  drawer: {
    display: "flex",
    height: "calc(100vh - 120px)",
    marginTop: "120px",
    width: drawerWidth,
    backgroundColor: "#070530",
    boxShadow: "0px 4px 40px 0px #0000000D",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    padding: 0,
    zIndex: 0,
    transition: "width 0.5s ease",
    // [theme.breakpoints.down("md")]: {
    //   height: "100vh",
    // },transition: "width 0.5s ease",
    [theme.breakpoints.down("md")]: {
      marginTop: "120px",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px",
      // overflowY: "auto",
      flexDirection: "column",
      height: "100%",
      flex: "1 0 auto",
      zIndex: "1200",
      position: "fixed",
      top: "0px",
      outline: "0px",
      left: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "110px",
    },
  },
  drawerWidth: {
    width: drawerWidth,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc(100vh - 120px)",
    overflowY: "hidden",
    overflowX: "hidden",
    transition: "width 0.5s ease",
    // [theme.breakpoints.down("xl")]: {
    //   width: drawerWidth,
    // },
    // [theme.breakpoints.down("md")]: {
    //   height: "100vh",
    // },
    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    // },
  },
  navLink: {
    textDecoration: "none",
    color: "#ffffff",
  },
  menuItems: {
    margin: "4px",
  },
  subNavBarLabel: {
    ...mediumFont,
    marginLeft: theme.spacing(7),
  },
  listItemIconStyle: {
    minWidth: "35px",
  },
  subMenuItems: {
    borderLeft: "4px solid",
  },
  menuOptionsHeight: {
    height: "calc(100vh - 120px)",
    overflowY: "auto",
    overflowX: "hidden",
    // paddingBottom: "150px",
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
    },
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      border: "1px solid #FFFFFF",
      background: "#282945",
    },
  },
  menuOptionListItem: {
    textDecoration: "none",
    color: "#ffffff",
    display: "flex",
    borderLeft: "4px solid transparent",
  },
  selectedMenuOptionListItem: {
    textDecoration: "none",
    color: "#ffffff",
    display: "flex",
    borderLeft: "4px solid",
    // height: "30px",
  },
  menuOption: {
    display: "flex",
    ...boldFont,
    textDecoration: "none",
    color: "#FFFFFFA6",
    borderLeft: "4px solid transparent",
    flexDirection: "column",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: "15px !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px !important",
    },
  },
  selectedMenuOption: {
    display: "flex",
    ...mediumFont,
    textDecoration: "none",
    color: "#ffffff",
    background: "#354247",
    // borderLeft: "4px solid " + primaryColorPurple,
    flexDirection: "column",
  },
  navBarLabel: {
    ...mediumFont,
    fontSize: getRelativeFontSize(6),
    marginLeft: "15px",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(4),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  selectedNavBarLabel: {
    ...boldFont,
    fontSize: getRelativeFontSize(6),
    marginLeft: "15px",
    background:
      "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(4),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },

  logoBox: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: "15px",
    height: "10vh",
    [theme.breakpoints.down("md")]: {
      height: "14vh",
    },
  },
  supportTicketBox: {
    borderRadius: "15px",
    mx: 5,
    mt: 1,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {},
    marginBottom: "10%",
  },
  workText: {
    ...boldFont,
    display: "flex",
    color: "#ffffff",
    gap: "5px",
  },
  sageText: {
    ...regularFont,
    color: "#ffffff",
  },
  btnBox: {
    ...regularFont,
    textTransform: "none",
  },
  imgBox: {
    background: "#22BAB6",
    width: "32px",
    height: "32px",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  getCampaignWrapper: {
    height: "25vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "16px",
    "& img": {
      width: "125px",
      height: "auto",
    },
  },
  getCampaignIcon: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  getCampaignImg: {
    width: "137px",
    height: "auto",
  },
  getCampaignPara: {
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#666666",
    ...regularFont,
    margin: "10px 0",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logOutWrapper: {
    height: "10vh",
    display: "flex",
    padding: "0 16px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: "15px",
    [theme.breakpoints.down("xl")]: {},
  },
  logOutLeft: {
    display: "flex",
    alignItems: "center",
  },
  squareBox: {
    width: "46px",
    height: "46px",
    borderRadius: "15px",
    background: "#DBDBDB",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarStyle: {
    ...boldFont,
    fontSize: getRelativeFontSize(10),
  },
  avatarFirstName: {
    color: "#212121",
    ...boldFont,
    fontSize: getRelativeFontSize(5),
    marginLeft: "8px",
    wordBreak: "break-all",
  },
  labelText: {
    ...boldFont,
    color: "#ffffff",
  },
  username: {
    ...regularFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(4),
  },
  userStack: {
    p: 1,
    pb: 1.5,
    cursor: "pointer",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.10)",
      borderRadius: "8px",
    },
  },
  selectedUserStack: {
    background: "rgba(255, 255, 255, 0.14)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    p: 1,
    pb: 1.5,
    borderRadius: "8px",
    cursor: "pointer",
  },
  drawerIconBox: {
    background: "#070530",
    width: "26px",
    height: "51px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "1px solid rgba(255, 255, 255, 0.3)", // Use rgba for better transparency
    zIndex: 1500, // No quotes around zIndex
    position: "fixed", // Fixed positioning to ensure visibility
    top: "135px", // Top positioning for desktop
    transition: "left 0.5s ease", // Transition for animation
    opacity: 1, // Ensure opacity is set to visible
    visibility: "visible", // Ensure it's not hidden
    willChange: "opacity",
    [theme.breakpoints.down("sm")]: {
      top: "125px", // Adjust top for smaller screens
    },
  },

  openDrawerIconBox: {
    borderRadius: "100px 0 0 100px", // Rounded corners for the open state
    borderRight: "none", // No right border in open state
    left: "263px", // Drawer is open and positioned to the right
    padding: "0 0 0 10px", // Padding to shift content
    position: "absolute", // Absolute positioning for the open drawer
    [theme.breakpoints.down("md")]: {
      top: "15px", // Adjust top position for medium screens
    },
  },

  closedDrawerIconBox: {
    borderRadius: "0 100px 100px 0", // Rounded corners for the closed state
    borderLeft: "none", // No left border in closed state
    left: 0, // Drawer is fully closed and positioned at left 0
    padding: "0 10px 0 0", // Padding to shift content
    position: "fixed",
    opacity: 1,
    visibility: "visible",
  },

  // Safari-specific styles using @supports hack
  "@supports (-webkit-appearance: none)": {
    drawerIconBox: {
      // Safari-specific styling or overrides can go here if needed
      transition: "left 0.5s ease", // Ensuring transition works smoothly
      opacity: 1, // Ensure opacity is set to visible
      visibility: "visible", // Ensure it's not hidden
      willChange: "opacity",
      WebkitBackfaceVisibility: "hidden", // Force re-rendering in Safari
      WebkitPerspective: 1000,
    },
  },
  typingIndicator: {
    color: "#ffffff",
    fontSize: "25px",
    animation: "fadeInOut 1s infinite",
    display: "inline",
    ...boldFont,
    pl: 0.5,
    letterSpacing: "10px",
  },
  unreadCount: {
    ...regularFont,
    fontSize: "15px",
    background: "#8a58cb",
    color: "#ffffff",
    borderRadius: "50%",
    padding: "4px 8px",
    marginRight: "22px",
  },
  preferenceBtn: {
    backgroundColor: "rgba(138, 49, 255, 0.15)",
    color: "#ffffff",
    borderRadius: "12px",
    padding: "12px 24px",
    transition: "all 0.3s ease",
    border: "1px solid rgba(138, 49, 255, 0.3)",
    "&:hover": {
      backgroundColor: "rgba(138, 49, 255, 0.25)",
      transform: "scale(1.05)",
    },
  },
  preferenceBtnText: {
    ...regularFont,
    fontSize: "18px",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  scrollBox: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      border: "1px solid #FFFFFF",
      background: "#282945",
    },
  },
  sectionHeader: {
    ...boldFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(5),
    padding: "8px 12px",
    background:
      "linear-gradient(90deg, rgba(255,255,255,0.14) 0%, rgba(255,255,255,0.08) 100%)",
    borderRadius: "8px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backdropFilter: "blur(8px)",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "&.bottom-section": {
      backgroundColor: "rgba(0,0,0,0.2)",
      boxShadow: "0 -4px 12px rgba(0,0,0,0.15)",
      borderTop: "1px solid rgba(255,255,255,0.1)",
      borderRadius: "12px 12px 0 0",
      padding: "12px 8px 8px 8px",
      marginTop: "12px",
    },
  },
  userListSection: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "8px",
  },
} as const;

const keyframes = `
  @keyframes fadeInOut {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
  }
`;

const styleSheet = document.styleSheets[0];
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default appDrawerStyles;
