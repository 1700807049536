import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import failImage from "../../../assets/images/failImage.png";
import { theme } from "../../../utils/styles";
import AppFooter from "../../Shared/AppFooter/AppFooter";
import AppHeader from "../../Shared/AppHeader/AppHeader";
import pricingStyles from "../Pricing.styles";

const Fail = () => {
  const classes = pricingStyles;
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getHeader = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} spacing={2}>
        <Typography sx={classes.italicTypo}>{t("failPayment")}</Typography>
        <Divider sx={classes.dividerStyle} />
      </Stack>
    );
  };

  const getContent = () => {
    return (
      <Stack direction={"column"} alignItems={"center"} pt={6}>
        {isMobile ? (
          <img src={failImage} height={"150px"} />
        ) : (
          <img src={failImage} />
        )}
        <Typography sx={classes.normalTypo} pt={1}>
          {t("failPaymentMsg1")}
        </Typography>
        <Typography sx={classes.boldTypo} pb={6}>
          {t("failPaymentMsg2")}
        </Typography>
        <Typography sx={classes.normalTypo} pt={4}>
          {t("closeWindow")}
        </Typography>
      </Stack>
    );
  };

  const getPage = () => {
    return (
      <Box sx={classes.content}>
        <Box sx={classes.mainContainer}>
          <Box sx={classes.mainBox}>
            <Box sx={classes.subBox}>
              <Container maxWidth="lg">
                {getHeader()}
                {getContent()}
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getFailPage = () => {
    return (
      <>
        <AppHeader />
        <Box sx={{ background: "#0000004f" }}>{getPage()}</Box>
        <AppFooter />
      </>
    );
  };

  return getFailPage();
};

export default Fail;
