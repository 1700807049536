export const generateMessageId = (
  sender: string,
  receiver: string,
  timestamp: string
) => {
  const str = `${sender}${receiver}${timestamp}`;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash);
};

export const generateTimestamp = () => {
  const now = new Date();
  const timestamp =
    now.getUTCFullYear() +
    "-" +
    String(now.getUTCMonth() + 1).padStart(2, "0") +
    "-" +
    String(now.getUTCDate()).padStart(2, "0") +
    "T" +
    String(now.getUTCHours()).padStart(2, "0") +
    ":" +
    String(now.getUTCMinutes()).padStart(2, "0") +
    ":" +
    String(now.getUTCSeconds()).padStart(2, "0");

  return timestamp;
};

export const convertToTimezone = (
  utcTime: string,
  timezone: string
): string => {
  try {
    const date = new Date(utcTime + "Z");
    return new Intl.DateTimeFormat("en-US", {
      timeZone: timezone,
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  } catch (error) {
    console.error("Error converting timezone:", error);
    return new Date(utcTime).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  } else {
    return dateString;
  }
};

export const getRandomWidth = () => {
  return Math.floor(Math.random() * (500 - 200 + 1)) + 200;
};
