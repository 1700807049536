import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import backArrow from "../../assets/icons/backArrow.svg";
import dobIcon from "../../assets/icons/dobIcon.svg";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import CustomLoader1 from "../../global/components/CustomLoader/CustomLoader1";
import CustomSelect from "../../global/components/CustomSelect/CustomSelect";
import strings from "../../global/constants/StringConstants";
import urls from "../../global/constants/UrlConstants";
import { handleErrorMessage } from "../../helpers/methods";
import { selectProfileId } from "../../redux/authSlice";
import history from "../../utils/history";
import { useAppSelector } from "../../utils/hooks";
import { theme } from "../../utils/styles";
import { formData } from "../Calculator/CalculatorData";
import { getProfileById } from "../Profile/ProfileService";
import analysisStyles from "./Analysis.styles";
import AnalysisCards from "./Components/Analysis/AnalysisCards";
import AnalysisLegends from "./Components/Analysis/AnalysisLegends";
import RaysLegends from "./Components/Analysis/RaysLegend";
import CircularBirthChart from "./Components/CircularBirthChart/CircularBirthChart";
import Football from "./Components/Football/Football";
import FootballLegend from "./Components/Football/FootballLegend";
import Mahadasha from "./Components/Mahadasha/Mahadasha";
import MahadashaDetails from "./Components/Mahadasha/MahadashaDetails";
import SingleDashaComponent from "./Components/Mahadasha/SingleDashaComponent";
import MineralsCards from "./Components/Minerals/MineralsCards";
import MineralsLegends from "./Components/Minerals/MineralsLegends";
import Nakshatra from "./Components/Nakshatra/Nakshatra";
import Planets from "./Components/Planets/Planets";

interface CustomProps {
  location?: any;
  selectedLanguage: string;
}

const Analysis = (props: CustomProps) => {
  const classes = analysisStyles;
  const { t } = useTranslation();
  const profileId = useAppSelector(selectProfileId);
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayVideo, setDisplayVideo] = useState<boolean>(false);
  const [goldenFoot, setGoldenFoot] = useState<boolean>(false);
  const [selectedSubTab, setSelectedSubTab] = useState<string>(strings.DATA);
  const [selectedLegend, setSelectedLegend] = useState<string>("");
  const [selectedHouse, setSelectedHouse] = useState<string>("D1");
  const [formFields, setFormFields] = useState(formData);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const location = props.location?.pathname?.split("/")[1].toLowerCase();

  const houseMenu = [
    {
      value: "D1",
      content: "D1",
    },
    {
      value: "D2",
      content: "D2",
    },
    {
      value: "D9",
      content: "D9",
    },
    {
      value: "D10",
      content: "D10",
    },
  ];

  useEffect(() => {
    profileId && getProfileDetails();
  }, []);

  useEffect(() => {
    setSelectedSubTab(strings.DATA);
  }, [location]);

  const getProfileDetails = async () => {
    try {
      setIsLoading(true);
      const response = await getProfileById(profileId!);
      setFormFields({
        firstName: {
          value: response.firstName,
          error: "",
        },
        lastName: {
          value: response.lastName,
          error: "",
        },
        bloodGroup: {
          value: response.bloodGroup,
          error: "",
        },
        sex: {
          value: response.sex,
          error: "",
        },
        birthDate: {
          value: response.birthDate,
          error: "",
        },
        birthTime: {
          value: response.birthTime,
          error: "",
        },
        birthPlace: {
          value: response.birthPlace,
          error: "",
        },
        pregnancy: {
          value: response.pregnancy,
          error: "",
        },
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleHouseChange = (event: SelectChangeEvent<string>) => {
    setSelectedHouse(event.target.value);
  };

  const getMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: classes.menuWrapper,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            setSelectedLegend(strings.PLANET);
            setSelectedSubTab("Legends");
          }}
        >
          <Typography sx={classes.menuItem}>{t("planetLegend")}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectedLegend(strings.RAYS);
            setSelectedSubTab("Legends");
          }}
        >
          <Typography sx={classes.menuItem}>{t("raysLegend")}</Typography>
        </MenuItem>
      </Menu>
    );
  };

  const getProfileData = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        px={{ sm: 5, xs: 5 }}
        pb={{ sm: 1, xs: 0 }}
        pt={1}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          sx={{ cursor: "pointer", width: "fit-content" }}
          onClick={() => history.push(urls.dashboardViewPath)}
        >
          {isMobile ? (
            <img src={backArrow} height={"20px"} alt="Module not found..." />
          ) : (
            <img src={backArrow} height={"25px"} alt="Module not found..." />
          )}
        </Box>
        <Grid
          container
          justifyContent={{ xl: "center", sm: "flex-start", xs: "center" }}
        >
          <Grid
            item
            px={{ sm: 4, xs: 2 }}
            py={{ sm: 0.5, xs: 0 }}
            pt={{ sm: 0, xs: 1 }}
          >
            <Stack
              direction={{ sm: "row", xs: "column" }}
              spacing={{ sm: 1, xs: 0 }}
              alignItems={"center"}
            >
              {!isTablet && (
                <img src={dobIcon} height={"35px"} alt="Module Not Found..." />
              )}
              <Typography
                sx={{ ...classes.cardLabel, textDecoration: "underline" }}
              >
                {t("birthDetails")}:
              </Typography>
              <Typography sx={classes.cardText}>
                {formFields?.birthDate?.value} - {formFields?.birthTime?.value}{" "}
                - {formFields?.birthPlace?.value}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getComponentBasedOnUrl = (
    location: string,
    subTabValue: string,
    selectedLegend?: string
  ) => {
    if (location === strings.ANALYSIS_TAB) {
      if (subTabValue === strings.DATA) {
        return (
          <AnalysisCards profileId={profileId!} setIsLoading={setIsLoading} />
        );
      } else {
        if (selectedLegend === strings.PLANET) {
          return <AnalysisLegends />;
        } else {
          return <RaysLegends />;
        }
      }
    }
    if (location === strings.MINERALS_TAB) {
      if (subTabValue === strings.DATA) {
        return (
          <MineralsCards profileId={profileId!} setIsLoading={setIsLoading} />
        );
      } else {
        return <MineralsLegends />;
      }
    }
    if (location === strings.MAHADASHA_TAB) {
      switch (subTabValue) {
        case strings.DATA:
          return (
            <Mahadasha profileId={profileId!} setIsLoading={setIsLoading} />
          );
        case strings.GOOD_TIME:
          return (
            <SingleDashaComponent
              subTabValue={subTabValue}
              profileId={profileId!}
              setIsLoading={setIsLoading}
            />
          );
        case strings.BAD_TIME:
          return (
            <SingleDashaComponent
              subTabValue={subTabValue}
              profileId={profileId!}
              setIsLoading={setIsLoading}
            />
          );
        case strings.CHANGES_IN_ONE_YEAR:
          return (
            <SingleDashaComponent
              subTabValue={subTabValue}
              profileId={profileId!}
              setIsLoading={setIsLoading}
            />
          );
        case strings.LEGENDS:
          return <MahadashaDetails />;
      }
    }
    if (location === strings.PLANET_TAB) {
      if (subTabValue === strings.DATA) {
        return (
          <Planets
            profileId={profileId!}
            house={selectedHouse}
            setIsLoading={setIsLoading}
          />
        );
      }
    }
    if (location === strings.FOOTBALL_TAB) {
      if (subTabValue === strings.DATA) {
        return (
          <Football
            profileId={profileId!}
            setIsLoading={setIsLoading}
            setDisplayVideo={setDisplayVideo}
            setGoldenFoot={setGoldenFoot}
          />
        );
      } else {
        return <FootballLegend />;
      }
    }
    if (location === strings.NAKSHATRA_TAB) {
      if (subTabValue === strings.DATA) {
        return <Nakshatra profileId={profileId!} setIsLoading={setIsLoading} />;
      }
    }
    if (location === strings.CIRCULAR_CHARTS_TAB) {
      if (subTabValue === strings.DATA) {
        return (
          <CircularBirthChart
            profileId={profileId!}
            setIsLoading={setIsLoading}
          />
        );
      }
    }
  };

  const getMainMenu = () => {
    return (
      <>
        <Box sx={classes.menuItemsWrapper}>
          <Stack
            direction={"row"}
            spacing={{ md: 4, xs: 2.5 }}
            pl={{ sm: 5, xs: 0 }}
            pb={1}
            pt={{ md: 0, xs: 1 }}
            justifyContent={"start"}
            sx={classes.menuItems}
          >
            <Typography
              sx={
                selectedSubTab === strings.DATA
                  ? { ...classes.selectedCardLabel, cursor: "pointer" }
                  : { ...classes.cardLabel, cursor: "pointer" }
              }
              onClick={() => setSelectedSubTab("Data")}
            >
              {t("data")}
            </Typography>

            {location === strings.MAHADASHA_TAB && (
              <>
                <Typography
                  sx={
                    selectedSubTab === strings.GOOD_TIME
                      ? { ...classes.selectedCardLabel, cursor: "pointer" }
                      : { ...classes.cardLabel, cursor: "pointer" }
                  }
                  onClick={() => setSelectedSubTab(strings.GOOD_TIME)}
                >
                  {t("goodTime")}
                </Typography>
                <Typography
                  sx={
                    selectedSubTab === strings.BAD_TIME
                      ? { ...classes.selectedCardLabel, cursor: "pointer" }
                      : { ...classes.cardLabel, cursor: "pointer" }
                  }
                  onClick={() => setSelectedSubTab(strings.BAD_TIME)}
                >
                  {t("badTime")}
                </Typography>
                <Typography
                  sx={
                    selectedSubTab === strings.CHANGES_IN_ONE_YEAR
                      ? { ...classes.selectedCardLabel, cursor: "pointer" }
                      : { ...classes.cardLabel, cursor: "pointer" }
                  }
                  onClick={() => setSelectedSubTab(strings.CHANGES_IN_ONE_YEAR)}
                >
                  {t("changesInOneYear")}
                </Typography>
              </>
            )}

            {location === strings.ANALYSIS_TAB ? (
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{ p: 0 }}
              >
                <Typography
                  sx={
                    selectedSubTab === strings.LEGENDS
                      ? {
                          ...classes.selectedCardLabel,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }
                      : {
                          ...classes.cardLabel,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }
                  }
                >
                  {t("legendsHeader")}
                  <KeyboardArrowDownIcon sx={{ fontSize: "1.2rem" }} />
                </Typography>
              </IconButton>
            ) : location === strings.PLANET_TAB ? (
              <CustomSelect
                menuItems={houseMenu}
                onChange={handleHouseChange}
                value={selectedHouse}
                name={"house"}
                id={"house"}
                customClasses={classes.darkTextField}
              />
            ) : (
              location !== strings.NAKSHATRA_TAB &&
              location !== strings.CIRCULAR_CHARTS_TAB && (
                <Typography
                  className="Test3"
                  sx={
                    selectedSubTab === strings.LEGENDS
                      ? { ...classes.selectedCardLabel, cursor: "pointer" }
                      : { ...classes.cardLabel, cursor: "pointer" }
                  }
                  onClick={() => setSelectedSubTab("Legends")}
                >
                  {t("legendsHeader")}
                </Typography>
              )
            )}
          </Stack>
        </Box>

        <Box sx={classes.scrollStyle}>
          {getComponentBasedOnUrl(location, selectedSubTab, selectedLegend)}
        </Box>
      </>
    );
  };

  const getData = () => {
    return (
      <Box
        sx={
          isTablet ? { ...classes.mainContainer, mt: 1 } : classes.mainContainer
        }
      >
        <Box sx={classes.mainBox}>
          <Box sx={classes.subBox}>{getMainMenu()}</Box>
        </Box>
      </Box>
    );
  };

  const getAnalysis = () => {
    return (
      <Box>
        {getProfileData()}
        {getData()}
        {getMenu()}
        <CustomLoader isLoading={isLoading} />
        <CustomLoader1 isLoading={displayVideo} goldenFoot={goldenFoot} />
      </Box>
    );
  };

  return getAnalysis();
};

export default Analysis;
