import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { handleErrorMessage } from "../../../helpers/methods";
import {
  selectChatLoading,
  selectChatUsers,
  selectReceiverUsers,
  selectSelectedUser,
  selectUnreadCounts,
  selectUserEmail,
  setChatLoadingAction,
  setChatUsersAction,
  setReceiverUsersAction,
  setSelectedUserAction,
  setTimezoneAction,
  setUnreadCountsAction,
} from "../../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import appDrawerStyles from "./AppDrawer.styles";
import { compatibilityAllUser } from "./UserDrawerItemService";

const BorderLinearProgress = styled(LinearProgress)(({ theme, value = 0 }) => {
  let barColor;
  if (value > 70) {
    barColor = "#2cc32c";
  } else if (value >= 50) {
    barColor = "#ffeb3b";
  } else {
    barColor = "#ff0000";
  }

  return {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: barColor,
    },
  };
});

export interface Users {
  username: string;
  firstName: string;
  lastName: string;
  percentage: string;
}

const UsersDrawerItem = () => {
  const classes = appDrawerStyles;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedUser = useAppSelector(selectSelectedUser);
  const loginUser = useAppSelector(selectUserEmail);
  const chatUsers = useAppSelector(selectChatUsers);
  const receiverUsers = useAppSelector(selectReceiverUsers);
  const chatLoading = useAppSelector(selectChatLoading);
  const unreadCounts = useAppSelector(selectUnreadCounts);
  const emptyUser = {
    username: "",
    firstName: "",
    lastName: "",
    percentage: "",
  };

  useEffect(() => {
    dispatch(setSelectedUserAction({ selectedUser: emptyUser }));
    getCompatibilityAllUser();
  }, []);

  const getCompatibilityAllUser = async () => {
    try {
      dispatch(setChatLoadingAction({ chatLoading: true }));
      const response = await compatibilityAllUser(loginUser);
      dispatch(
        setChatUsersAction({ chatUsers: response.compatibilityResults })
      );
      dispatch(
        setReceiverUsersAction({ receiverUsers: response.receiverResults })
      );
      dispatch(setTimezoneAction({ timezone: response.timezone }));
      dispatch(setChatLoadingAction({ chatLoading: false }));
    } catch (error: any) {
      handleErrorMessage(error);
    }
  };

  const handleSelectedUserChange = (user: Users) => {
    const updatedCounts = unreadCounts?.map((unread) =>
      unread.target === user.username ? { ...unread, count: 0 } : unread
    );
    dispatch(setSelectedUserAction({ selectedUser: user }));
    dispatch(setUnreadCountsAction({ unreadCounts: updatedCounts }));
  };

  const getUsersDrawerItem = () => {
    const sortedChatUsers = [...(chatUsers || [])].sort((a, b) => {
      const aUnread =
        unreadCounts?.find((unread) => unread.target === a.username)?.count ||
        0;
      const bUnread =
        unreadCounts?.find((unread) => unread.target === b.username)?.count ||
        0;
      if (aUnread !== bUnread) return bUnread - aUnread;
      return +b.percentage - +a.percentage;
    });

    const sortedReceiverUsers = [...(receiverUsers || [])].sort((a, b) => {
      const aUnread =
        unreadCounts?.find((unread) => unread.target === a.username)?.count ||
        0;
      const bUnread =
        unreadCounts?.find((unread) => unread.target === b.username)?.count ||
        0;
      if (aUnread !== bUnread) return bUnread - aUnread;
      return +b.percentage - +a.percentage;
    });

    const hasReceivers = receiverUsers && receiverUsers.length > 0;
    const hasChatUsers = chatUsers && chatUsers.length > 0;
    const bothPresent = hasReceivers && hasChatUsers;

    const chatUsersHeight = bothPresent ? "60%" : "100%";
    const receiversHeight = bothPresent ? "40%" : "100%";

    return (
      <Stack
        direction="column"
        spacing={1}
        py={2}
        px={1}
        height={"calc(100% - 32px)"}
      >
        {!chatLoading && (
          <>
            {hasChatUsers && (
              <Box sx={{ height: chatUsersHeight, ...classes.scrollBox }}>
                <Box sx={classes.sectionContainer}>
                  <Box sx={classes.sectionHeader}>
                    <Typography component="span">Your Preference</Typography>
                  </Box>
                  <Box sx={classes.userListSection}>
                    {sortedChatUsers?.map((user) => {
                      const unreadCount =
                        unreadCounts?.find(
                          (unread) => unread.target === user.username
                        )?.count || 0;

                      return (
                        <Stack
                          key={user.username}
                          direction="column"
                          spacing={2}
                          sx={
                            user.username === selectedUser.username
                              ? classes.selectedUserStack
                              : classes.userStack
                          }
                          onClick={() => handleSelectedUserChange(user)}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography sx={classes.username}>
                              {user.firstName + " " + user.lastName}
                            </Typography>
                            {unreadCount > 0 && (
                              <Box sx={classes.unreadCount}>{unreadCount}</Box>
                            )}
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Box sx={{ flexGrow: 1, mr: 2 }}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={+user.percentage}
                              />
                            </Box>
                            <Typography
                              sx={{ ...classes.username, fontSize: "16px" }}
                            >
                              {user.percentage + "%"}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            )}
            {hasReceivers && (
              <Box sx={{ height: receiversHeight, ...classes.scrollBox }}>
                <Box
                  sx={{
                    ...classes.sectionContainer,
                    "&.bottom-section": { mt: 0 },
                  }}
                  className="bottom-section"
                >
                  <Box sx={classes.sectionHeader}>
                    <Typography component="span">New Requests</Typography>
                  </Box>
                  <Box sx={classes.userListSection}>
                    {sortedReceiverUsers?.map((user) => {
                      const unreadCount =
                        unreadCounts?.find(
                          (unread) => unread.target === user.username
                        )?.count || 0;

                      return (
                        <Stack
                          key={user.username}
                          direction="column"
                          spacing={2}
                          sx={
                            user.username === selectedUser.username
                              ? classes.selectedUserStack
                              : classes.userStack
                          }
                          onClick={() => handleSelectedUserChange(user)}
                        >
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography sx={classes.username}>
                              {user.firstName + " " + user.lastName}
                            </Typography>
                            {unreadCount > 0 && (
                              <Box sx={classes.unreadCount}>{unreadCount}</Box>
                            )}
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Box sx={{ flexGrow: 1, mr: 2 }}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={+user.percentage}
                              />
                            </Box>
                            <Typography
                              sx={{ ...classes.username, fontSize: "16px" }}
                            >
                              {user.percentage + "%"}
                            </Typography>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
        {chatLoading && (
          <Typography
            sx={{ ...classes.username, fontSize: "25px" }}
            mx={2.5}
            height={"100%"}
            alignContent={"center"}
          >
            {t("findingMatch")}
            <Typography sx={classes.typingIndicator}>...</Typography>
          </Typography>
        )}
      </Stack>
    );
  };

  return getUsersDrawerItem();
};

export default UsersDrawerItem;
