import {
  boldFont,
  getRelativeFontSize,
  mediumFont,
  regularFont,
  theme,
} from "../../utils/styles";

const analysisStyles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    mx: 5,
    [theme.breakpoints.down("sm")]: {
      mx: 2,
    },
  },
  mainBox: {
    p: 1,
    borderRadius: "28px",
    height: "calc(100vh - 210px)",
    background: "rgba(255, 255, 255, 0.07)",
    backdropFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",

    maxWidth: "1750px",
    width: "100%",
    [theme.breakpoints.down(1311)]: {
      height: "calc(100vh - 230px)",
    },
    [theme.breakpoints.down("md")]: {
      mb: 2,
      height: "calc(100vh - 235px)",
    },
    [theme.breakpoints.down("sm")]: {
      mb: 1,
    },
  },
  subBox: {
    p: 3,
    pt: 1.5,
    height: "calc(100vh - 248px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.14)",
    [theme.breakpoints.down(1311)]: {
      height: "calc(100vh - 268px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 273px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 255px)",
      p: 1,
    },
  },
  scrollStyle: {
    height: "calc(100vh - 263px)",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      border: "1px solid #FFFFFF",
      background: "#282945",
    },
    [theme.breakpoints.down(1311)]: {
      height: "calc(100vh - 283px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 298px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 285px)",
    },
  },
  headerText: {
    ...mediumFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(7),
    pl: 1,
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(4),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
      pl: 0,
    },
  },
  cardStyle: {
    borderRadius: "20px",
    background: "rgba(255, 255, 255, 0.14)",
    backdropSFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    padding: "12px 25px",
    alignItems: "center",
    height: "100%",
    minHeight: "150px",
    justifyContent: "end",

    cursor: "pointer",
    transformStyle: "preserve-3d",
    transformOrigin: "center right",
    transition: "transform 1s",
    "&.is-flipped": {
      // transform: "translateX(-100%) rotateY(-180deg)",
      WebkitTransform: "rotateY(-180deg) translate(100%, 0)",
      transform: "rotateY(-180deg) translate(100%, 0)",
    },

    [theme.breakpoints.down("xl")]: {
      minHeight: "175px",
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: "155px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  cardLabel: {
    ...mediumFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
    },
  },
  selectedCardLabel: {
    ...mediumFont,
    color: "#6B72FF",
    fontSize: getRelativeFontSize(4),
    borderBottom: "2px solid #6B72FF",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
    },
  },
  cardText: {
    ...regularFont,
    color: "#ffffffe8",
    fontSize: getRelativeFontSize(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-2),
    },
  },
  labelTypo: {
    ...regularFont,
    color: "#ffffffa6",
    fontSize: getRelativeFontSize(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
    },
  },
  valueTypo: {
    ...mediumFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  flipLabelType: {
    ...regularFont,
    color: "#ffffffa6",
    fontSize: getRelativeFontSize(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
    // transform: "translateX(0%) rotateY(180deg)",
  },
  flipCardContent: {
    transform: "rotateY(180deg)",
    width: "100%",
  },
  legendsGrid: {
    [theme.breakpoints.down("lg")]: {
      borderBottom: "2px solid transparent",
      borderImage: `linear-gradient(90deg, rgba(255, 255, 255, 0) -0.69%, #ffffff54 50.58%, rgba(255, 255, 255, 0) 99.95%)`,
      borderImageSlice: "1",
    },
  },
  gradientTypo: {
    background:
      "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    ...boldFont,
    fontSize: getRelativeFontSize(7),
    pl: 1,
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(4),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
      pl: 0,
    },
  },
  tabWrapper: {
    minHeight: "60px",
    py: 1,
    [theme.breakpoints.down("md")]: {
      minHeight: "40px",
      py: 0,
    },
  },
  mineralsCardStyle: {
    borderRadius: "20px",
    background: "rgba(255, 255, 255, 0.14)",
    backdropSFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    padding: "10px",
    alignItems: "center",
    height: "100%",
    minHeight: "100px",
    cursor: "pointer",

    transformStyle: "preserve-3d",
    transformOrigin: "center right",
    transition: "transform 1s",
    "&.is-flipped": {
      // transform: "translateX(-100%) rotateY(-180deg)",
      WebkitTransform: "rotateY(-180deg) translate(100%, 0)",
      transform: "rotateY(-180deg) translate(100%, 0)",
    },

    [theme.breakpoints.down("xl")]: {
      minHeight: "110px",
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 20px",
    },
  },
  mineralsTypo: {
    ...regularFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  mahadashaHeader: {
    background: "transparent",
    borderRadius: "20px",
    border: "1px solid rgb(111 111 111 / 80%)",
    padding: "15px",
    alignItems: "center",
    height: "100%",
    cursor: "auto",
    display: "flex",
    // alignItems: "baseline",
  },
  mahadashaContent: {
    borderRadius: "20px",
    background: "rgba(255, 255, 255, 0.14)",
    backdropSFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    padding: "15px",
    alignItems: "center",
    height: "100%",
    // minHeight: "80px",
    cursor: "pointer",
  },
  dashaNameTypo: {
    ...mediumFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(7),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  dashaTagline: {
    ...boldFont,
    color: "#FFFFFF",
    width: "fit-content",
    fontSize: getRelativeFontSize(5),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
    },
  },
  planetNameTypo: {
    ...mediumFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(5),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  dateTypo: {
    ...regularFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  noDataWrapper: {
    borderRadius: "24px",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    background: "rgba(255, 255, 255, 0.14)",
    backdropFilter: "blur(24px)",
    p: 1.5,
  },
  noDataTypo: {
    ...regularFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(2),
    textAlign: "center",
  },
  btnStyle: {
    backgroundImage:
      "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
    borderRadius: "8px",
    boxSizing: "border-box",
    // maxWidth: "200px",
    display: "block",
    height: "54px",
    letterSpacing: "1px",
    margin: "0 10px",
    padding: "4px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    // width: "250px",
    zIndex: 2,

    "&.hover": {
      color: "#fff",
    },

    "& .btnText": {
      alignItems: "center",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      height: "100%",
      transition: "background .5s ease",
      width: "100%",
      ...boldFont,
      // fontSize: getRelativeFontSize(3),
      color:
        "linear-gradient(90.51deg, #DF66E3 0.27%, #5E75FF 53.25%, #2CF1DF 102.53%)",
      background:
        "linear-gradient(90deg, #FC72FF 0%, #8F68FF 25%, #487BFF 50%, #2CD9FF 75%, #2CFFCC 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      // ...mediumFont,
      fontSize: getRelativeFontSize(2),
      [theme.breakpoints.down("md")]: {
        fontSize: getRelativeFontSize(0),
      },
    },
  },
  btnBox: {
    background: "#271642",
    padding: "11px 15px",
    borderRadius: "8px",
    border: "1px solid rgb(255 255 255 / 0%)",
    margin: "-2px",
  },
  btnWrapper: {
    justifyContent: "end",
    [theme.breakpoints.down("md")]: {
      maxWidth: "260px",
    },
  },
  websiteBox: {
    ml: 0,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    textDecoration: "none",
    justifyContent: "center",
  },
  detailsTypo: {
    ...mediumFont,
    color: "#ffffffb8",
    fontSize: getRelativeFontSize(2),
    display: "list-item",
    listStyleType: "disclosure-closed",
    pl: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  mahadashaContentWrapper: {
    marginLeft: "20px",
  },
  tableHeader: {
    ...mediumFont,
    color: "#ffffffb8",
    fontSize: getRelativeFontSize(2),
    pl: 1,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  tableBody: {
    ...mediumFont,
    color: "#ffffff",
    fontSize: getRelativeFontSize(2),
    pl: 1,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  legendContainer: {
    borderBottom: "2px solid transparent",
    borderImage: `linear-gradient(90deg, rgba(255, 255, 255, 0) -0.69%, #ffffff54 50.58%, rgba(255, 255, 255, 0) 99.95%)`,
    borderImageSlice: "1",
  },
  legendDivider: {
    border: "1px solid transparent",
    borderImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff54 100%)`,
    borderImageSlice: "1",
  },
  planetTableContainer: {
    background: "transparent",
    border: "1px solid #ffffff4f",
    borderRadius: "0px",
    textAlignLast: "center",
  },
  planetTableHead: {
    "& .MuiTableRow-head th:first-child": {
      border: "none",
      borderRight: "1px solid #ffffff4f",
    },
    "& .MuiTableRow-head th:last-child": { border: "none" },
  },
  planetTableBody: {
    "& .MuiTableRow-root th:first-child": {
      border: "none",
      borderRight: "1px solid #ffffff4f",
    },
    "& .MuiTableRow-root td:last-child": { border: "none" },
  },
  menuWrapper: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    px: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  menuItem: {
    ...regularFont,
    color: "#222222",
    fontSize: getRelativeFontSize(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  pointsTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#ffffffa6",
    display: "list-item",
    listStyle: "disc",
    ml: 2.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
      ml: 0,
    },
  },
  bulletTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(1),
    color: "#ffffffa6",
    display: "list-item",
    listStyleType: "disclosure-closed",
    ml: 2.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
      ml: 0,
    },
  },
  subHeaderTypo: {
    ...regularFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  centerGrid: {
    display: "grid",
    // justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "start",
    },
  },
  verticalDivider: {
    border: "1px solid #ffffff54",
    [theme.breakpoints.down("lg")]: {
      border: "none",
    },
  },
  readMoreTypo: {
    ...mediumFont,
    color: "#6B72FF",
    fontSize: getRelativeFontSize(1),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(-1),
    },
  },
  darkTextField: {
    // maxWidth: "180px",
    // width: "100%",
    borderRadius: "0px",
    border: "none",
    background: "transparent",
    // backdropFilter: "blur(24px)",
    boxShadow: "none",

    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      border: "none",
      position: "relative",
      padding: "0px 0px",
      color: "#ffffff",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 0px",
      },

      "&::placeholder": {
        ...mediumFont,
        color: "#ffffffA6",
      },
    },
    "& .MuiSelect-icon": {
      color: "#ffffff",
    },
    "& ..MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
  planetCardStyle: {
    borderRadius: "20px",
    background: "rgba(255, 255, 255, 0.14)",
    backdropSFilter: "blur(24px)",
    border: "1px solid rgba(255, 255, 255, 0.21)",
    alignItems: "center",
    height: "100%",
    minHeight: "150px",
    justifyContent: "end",

    // cursor: "pointer",
    transformStyle: "preserve-3d",
    transformOrigin: "center right",
    transition: "transform 1s",
    // "&.is-flipped": {
    //   // transform: "translateX(-100%) rotateY(-180deg)",
    //   WebkitTransform: "rotateY(-180deg) translate(100%, 0)",
    //   transform: "rotateY(-180deg) translate(100%, 0)",
    // },

    [theme.breakpoints.down("xl")]: {
      minHeight: "175px",
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: "155px",
    },
  },
  planetNameBox: {
    background: "#9053F6",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    justifyContent: "space-between",
    alignItems: "center",
    py: 1,
    px: 2,
  },
  greenText: {
    ...regularFont,
    fontSize: getRelativeFontSize(3),
    color: "#00b300",
    display: "list-item",
    listStyle: "square",
    ml: "10px !important",
  },
  greenLargeText: {
    fontSize: getRelativeFontSize(4),
  },
  redText: {
    ...regularFont,
    fontSize: getRelativeFontSize(3),
    color: "#ff1f1f",
    display: "list-item",
    listStyle: "square",
    ml: "10px !important",
  },
  menuItemsWrapper: {
    width: "calc(100vw - 473px)",
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 135px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 85px)",
    },
  },
  menuItems: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    overflowX: "auto",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar": {
      height: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      border: "1px solid #FFFFFF",
      background: "#282945",
    },
  },
} as const;

export default analysisStyles;
