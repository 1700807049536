import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, Stack } from "@mui/material";
import React from "react";
import urls from "../global/constants/UrlConstants";
import { logOutAction } from "../redux/authSlice";
import { logout } from "../screens/Shared/AppHeader/AppHeaderServices";
import errorBoundaryStyle from "./ErrorBoundary.style";
import { store } from "./store";

export default class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean; errorDetails: string; component: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorDetails: "", component: "" };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    const errorInString = error.toString();
    const componentStack = errorInfo.componentStack;
    const subString = componentStack.substring(0, 200);
    this.setState({
      errorDetails: errorInString,
      component: componentStack,
    });
    this.sendNotifyToDiscord(errorInString, componentStack);
  }

  sendNotifyToDiscord = async (title: any, description: any) => {
    try {
      // const body = {
      //   username: "Astrology",
      //   avatar_url: "",
      //   content: "Oops! Something Went wrong",
      //   embeds: [
      //     {
      //       title: title,
      //       description: description,
      //     },
      //   ],
      // };
      // const callParams = getNoAuthCallParams("POST", body);
      //const response = await makeCall(urls.webhook_URL, callParams);
    } catch (error: any) {
      console.error(error);
    }
  };

  handleRefresh = () => {
    window.location.reload();
  };

  handleLogout = async () => {
    const firebaseToken = store.getState().auth.firebaseToken;
    try {
      await logout(firebaseToken);
      setTimeout(() => {
        store.dispatch(logOutAction());
        window.location.href = urls.homeViewPath;
      }, 1000);
    } catch (error: any) {
      console.log(error.errorMessage);
    }
  };

  render() {
    const classes = errorBoundaryStyle;

    if (this.state.hasError) {
      return (
        <Box sx={classes.mainContainer}>
          <Box sx={classes.contentBox}>
            <ErrorOutlineIcon sx={classes.errorIcon} />
            <Box sx={classes.heading}>Oops! Something went wrong</Box>
            <Box sx={classes.subText}>
              We apologize for the inconvenience. Please try refreshing the page
              or logging out.
            </Box>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                startIcon={<RefreshIcon />}
                onClick={this.handleRefresh}
                sx={classes.refreshBtn}
              >
                Refresh Page
              </Button>
              <Button
                variant="contained"
                startIcon={<LogoutIcon />}
                onClick={this.handleLogout}
                sx={classes.logoutBtn}
              >
                Logout
              </Button>
            </Stack>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}
