import activeAnalysisIcon from "../assets/icons/activeAnalysisIcon.svg";
import activeCircularChartIcon from "../assets/icons/activeCircularChartIcon.svg";
import activeFootballIcon from "../assets/icons/activeFootballIcon.svg";
import activeMahadashaIcon from "../assets/icons/activeMahadashaIcon.svg";
import activeMissingMineralsIcon from "../assets/icons/activeMissingMineralsIcon.svg";
import activeNakshatraIcon from "../assets/icons/activeNakshatraIcon.svg";
import activePlanetsIcon from "../assets/icons/activePlanetsIcon.svg";
import analysisIcon from "../assets/icons/analysisIcon.svg";
import circularChartIcon from "../assets/icons/circularChartIcon.svg";
import footballIcon from "../assets/icons/footballIcon.svg";
import mahadashaIcon from "../assets/icons/mahadashaIcon.svg";
import missingMineralsIcon from "../assets/icons/missingMineralsIcon.svg";
import nakshatraIcon from "../assets/icons/nakshatraIcon.svg";
import planetsIcon from "../assets/icons/planetsIcon.svg";
import strings from "../global/constants/StringConstants";
import urls from "../global/constants/UrlConstants";
import { AuthMap } from "../models/interfaces";
import { logOutAction } from "../redux/authSlice";
import { store } from "./store";

const AuthMapper = {
  Admin: [],
  User: [],
} as AuthMap;

export const doesUserHasAccessTo = (componentName: string) => {
  // const role = "Admin";
  // if (!doesRoleExistInLocalStorage(role)) {
  //   return [];
  // }
  // const userAuthorizedPages = AuthMapper[role];
  // return userAuthorizedPages.includes(componentName);
  return true;
};

export const generatedSubMenu = (subMenu: any) => {
  const role = "";
  if (!doesRoleExistInLocalStorage(role)) {
    return [];
  }
  const userRole = AuthMapper[role];
  const generatedMenu: any[] = [];
  subMenu.forEach((menu: any) => {
    if (userRole?.includes(menu.pageName)) {
      generatedMenu.push(menu);
    }
  });
  return generatedMenu;
};

const doesRoleExistInLocalStorage = (role: string) => {
  if (!role) {
    store.dispatch(logOutAction());
    return false;
  }
  return true;
};

const PageMapper = {
  back: [
    strings.HOME,
    strings.DASHBOARD,
    strings.SUCCESS,
    strings.FAIL,
    strings.LOGIN,
    strings.REGISTER,
    strings.SET_PASSWORD,
    strings.OTP,
    strings.RESET_PASSWORD,
    strings.DELETE_ACCOUNT,
    strings.PRIVACY_POLICY,
  ],
  moon: [
    strings.HOME,
    strings.SUCCESS,
    strings.FAIL,
    strings.LOGIN,
    strings.REGISTER,
    strings.SET_PASSWORD,
    strings.OTP,
    strings.RESET_PASSWORD,
    strings.DELETE_ACCOUNT,
    strings.PRIVACY_POLICY,
  ],
  profile: [
    strings.HOME,
    strings.SUCCESS,
    strings.FAIL,
    strings.LOGIN,
    strings.REGISTER,
    strings.SET_PASSWORD,
    strings.OTP,
    strings.RESET_PASSWORD,
    strings.DELETE_ACCOUNT,
    strings.PRIVACY_POLICY,
  ],
  notification: [
    strings.HOME,
    strings.SUCCESS,
    strings.FAIL,
    strings.LOGIN,
    strings.REGISTER,
    strings.SET_PASSWORD,
    strings.OTP,
    strings.RESET_PASSWORD,
    strings.DELETE_ACCOUNT,
    strings.PRIVACY_POLICY,
  ],
  appdrawer: [
    strings.HOME,
    strings.DASHBOARD,
    strings.PRICING,
    strings.PAYMENT,
    strings.USER_PROFILE,
    strings.SUBSCRIPTION,
    strings.UPDATE_PASSWORD,
    strings.PROFILE,
    strings.PROFESSION_ANALYSIS,
    strings.CALCULATOR,
    strings.SUCCESS,
    strings.FAIL,
    strings.LOGIN,
    strings.REGISTER,
    strings.SET_PASSWORD,
    strings.OTP,
    strings.RESET_PASSWORD,
    strings.DELETE_ACCOUNT,
    strings.PRIVACY_POLICY,
  ],
} as AuthMap;

export const doesPageHasComponent = (component: string) => {
  const page = window.location.pathname.split("/")[1];
  const userAuthorizedComponent = PageMapper[component.toLowerCase()];
  return userAuthorizedComponent && !userAuthorizedComponent.includes(page);
};

const ListOfMenuItems: any = [
  {
    icon: analysisIcon,
    activeIcon: activeAnalysisIcon,
    text: "analysisHeader",
    key: "/analysis",
    link: urls.analysisViewPath,
    pageName: strings.DASHBOARD,
  },
  {
    icon: missingMineralsIcon,
    activeIcon: activeMissingMineralsIcon,
    text: "mineralsHeader",
    key: "/minerals",
    link: urls.analysisViewPath,
    pageName: strings.DASHBOARD,
  },
  {
    icon: mahadashaIcon,
    activeIcon: activeMahadashaIcon,
    text: "mahadashaHeader",
    key: "/mahadasha",
    link: urls.analysisViewPath,
    pageName: strings.DASHBOARD,
  },
  {
    icon: planetsIcon,
    activeIcon: activePlanetsIcon,
    text: "planetHeader",
    key: "/planet-details",
    link: urls.analysisViewPath,
    pageName: strings.DASHBOARD,
  },
  {
    icon: footballIcon,
    activeIcon: activeFootballIcon,
    text: "footballHeader",
    key: "/football-position-prediction",
    link: urls.footballPredictionViewPath,
    pageName: strings.DASHBOARD,
  },
  {
    icon: nakshatraIcon,
    activeIcon: activeNakshatraIcon,
    text: "nakshatraHeader",
    key: "/nakshatra-analysis",
    link: urls.nakshatraAnalysisViewPath,
    pageName: strings.DASHBOARD,
  },
  {
    icon: circularChartIcon,
    activeIcon: activeCircularChartIcon,
    text: "circularChartHeader",
    key: "/circular-chart",
    link: urls.circularChartViewPath,
    pageName: strings.DASHBOARD,
  },
];

export const generateMenu = () => {
  const generatedMenu: any[] = [];
  ListOfMenuItems.forEach((menu: any) => {
    generatedMenu.push(menu);
  });
  return generatedMenu;
};
